<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1280" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <AddressEditor
      ref="addressEditor"
      @addressConfigured="handleAddress"
      v-model="addressEditorDialog"
      :address_types="address_types"
    />
    <messageBox ref="msgBox" />

    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    editingCustomer
                      ? $t("customer-editor-modify")
                      : $t("customer-editor-add")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />

            <v-progress-circular
              v-if="fetchingData"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <ValidationObserver
              v-if="!fetchingData"
              ref="obs"
              v-slot="{ invalid, validated }"
            >
              <v-card-text>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        outlined
                        dense
                        :label="$t('customer-editor-hint-type')"
                        v-model="customer.type"
                        :items="customer_types"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        :success="valid"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        outlined
                        dense
                        :label="$t('customer-editor-hint-category')"
                        v-model="customer.category_id"
                        :items="customer_categories"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        :success="valid"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense v-if="customer.type == 1 || customer.type == 3">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        key="business-name"
                        v-model="customer.business_name"
                        :label="$t('customer-editor-hint-business-name')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense v-if="customer.type == 2">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        key="first-name"
                        v-model="customer.first_name"
                        :label="$t('customer-editor-hint-first-name')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      v-if="customer.type == 2"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        key="last-name"
                        v-model="customer.last_name"
                        :label="$t('customer-editor-hint-last-name')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :rules="{ required: true, email: true }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="customer.email"
                        :label="$t('customer-editor-hint-email')"
                        :error-messages="errors"
                        :success="valid"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="customer.secondary_email"
                      :label="$t('customer-editor-hint-secondary-email')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-select
                      :label="$t('pricelist-editor-hint-master')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-model="customer.price_list_id"
                      :items="pricelists"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="customer.fda_number"
                      :label="$t('customer-editor-hint-fda-number')"
                      outlined
                      maxlength="11"
                      dense
                    ></v-text-field> </v-col
                ></v-row>
              </v-card-text>
              <div style="height:10px" />
              <v-card>
                <v-toolbar height="40" color="info" dark>
                  <v-col cols="1"> </v-col
                  ><v-col cols="10">{{
                    $t("customer-editor-title-addresses")
                  }}</v-col>
                  <v-col class="text-right" cols="1">
                    <v-btn
                      v-if="$store.state.hasRight('158')"
                      icon
                      @click="addAddress()"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-toolbar>

                <v-container fluid>
                  <v-row v-if="fetchingAddresses">
                    <v-col col="12">
                      <v-progress-circular
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row v-if="!fetchingAddresses">
                    <v-col col="12">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("address-tbl-type") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-nation") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-address") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-city") }}
                            </th>
                            <th class="text-center">
                              {{ $t("address-tbl-pc") }}
                            </th>
                            <th class="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in customer.addresses"
                            :key="index"
                          >
                            <td class="text-center">
                              {{ $t(item.type_name) }}
                            </td>
                            <td class="text-center">{{ item.nation }}</td>
                            <td class="text-center">{{ item.address }}</td>
                            <td class="text-center">
                              {{ item.city ? item.city : item.cityname }}
                            </td>
                            <td class="text-center">{{ item.postal_code }}</td>
                            <td class="text-center">
                              <v-tooltip
                                v-if="$store.state.hasRight('159')"
                                bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="gray"
                                    @click.stop="editAddress(item)"
                                    v-on="on"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("address-action-edit")
                                }}</span></v-tooltip
                              >

                              <v-tooltip
                                v-if="$store.state.hasRight('160')"
                                bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="error"
                                    @click.stop="deleteAddress(item.id)"
                                    v-on="on"
                                    ><v-icon
                                      >mdi-delete-circle-outline</v-icon
                                    ></v-btn
                                  ></template
                                ><span>{{
                                  $t("address-action-delete")
                                }}</span></v-tooltip
                              >
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <div style="height:10px" />

              <v-btn
                width="120"
                :key="ok_counter"
                :disabled="invalid || fetchingAddresses"
                v-on:click="confirm()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                :disabled="fetchingAddresses"
                width="120"
                v-on:click="cancel()"
                color="secondary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<style scoped>
.card-title-text {
  font-size: 1.5em;
}
</style>

<script>
import AddressEditor from "./AddressEditor";
import messageBox from "../components/MessageBox";
import staticData from "../apis/static";
import priceListsManager from "../apis/pricelists";
import customersManager from "../apis/customers";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      customer: {
        addresses: [],
        reference_color: "#6d2533"
      },
      vat_exemptions: [],
      ok_counter: 0,
      customer_types: [],
      customer_categories: [],
      address_types: [],
      pricelists: [],
      addressEditorDialog: false,
      editingCustomer: false,
      fetchingAddresses: false,
      fetchingData: true,
      colorPickerColor: "#6d2533",
      days: staticData.days,
      payment_modes: staticData.payment_modes,
      menu: false
    };
  },

  computed: {
    swatchStyle() {
      const { colorPickerColor, menu } = this;
      return {
        backgroundColor: colorPickerColor,
        cursor: "pointer",
        height: "25px",
        width: "25px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    AddressEditor,
    messageBox
  },

  props: ["value"],

  mounted() {},

  methods: {
    has_invoice_address: function() {
      for (let n = 0; n < this.customer.addresses.length; n++) {
        let address = this.customer.addresses[n];
        if (address.address_type == 2) return true;
      }
      return false;
    },

    fetchAddresses: function(customer_id, unique) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchAddresses(customer_id, unique)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    addAddress: function() {
      this.$refs.addressEditor.setItem(false, null, this.customer.type);
      this.addressEditorDialog = true;
    },

    locateAddress: function(address) {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" +
          encodeURI(
            address.address +
              " " +
              (address.city ? address.city : address.cityname)
          )
      );
    },

    editAddress: function(address) {
      this.$refs.addressEditor.setItem(true, address, this.customer.type);
      this.addressEditorDialog = true;
    },

    deleteAddress: function(id) {
      this.$refs.msgBox
        .show(
          this.$t("address-delete-confirm"),
          this.$t("address-delete-title")
        )
        .then(() => {
          this.fetchingAddresses = true;
          customersManager
            .removeAddress(id)
            .then(() => {
              this.fetchAddresses(this.customer.id, true).then(res => {
                this.customer.addresses = [
                  ...res.invoice_addresses,
                  ...res.shipment_addresses
                ];
                this.fetchingAddresses = false;
                this.ok_counter++;
              });
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    handleAddress: function(context) {
      if (context.editingAddress) {
        if (this.customer.id) {
          customersManager
            .updateAddress(context.address)
            .then(() => {
              this.fetchingAddresses = true;
              this.fetchAddresses(this.customer.id, true).then(res => {
                this.customer.addresses = [
                  ...res.invoice_addresses,
                  ...res.shipment_addresses
                ];
                this.fetchingAddresses = false;
                this.addressEditorDialog = false;
                this.ok_counter++;
              });
            })
            .catch(err => {
              console.log(err);
              this.addressEditorDialog = false;
            });
        } else {
          this.fetchingAddresses = false;
          this.addressEditorDialog = false;
          this.ok_counter++;
        }
      } else {
        if (!this.customer.id) {
          this.customer.addresses.push(context.address);
        } else {
          context.address.customer_id = this.customer.id;

          customersManager
            .addAddress(context.address)
            .then(() => {
              this.fetchingAddresses = true;
              this.fetchAddresses(this.customer.id, true).then(res => {
                this.customer.addresses = [
                  ...res.invoice_addresses,
                  ...res.shipment_addresses
                ];
                this.fetchingAddresses = false;
                this.ok_counter++;
              });
            })
            .catch(err => {
              console.log(err);
            });
        }

        this.addressEditorDialog = false;
      }
    },

    confirm: function() {
      this.customer.reference_color = this.colorPickerColor;

      this.$emit("customerConfigured", {
        editingCustomer: this.editingCustomer,
        customer: this.customer
      });
      this.$refs.obs.reset();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    resetModal() {
      this.customer.type = 1;
      this.customer.business_name = "";
      this.customer.first_name = "";
      this.customer.last_name = "";
      this.customer.addresses = [];
      this.payment_mode = 1;
      this.colorPickerColor = "#6d2533";

      this.customer.vat_exemption_id = 0;
    },

    setItem(editing, customer) {
      this.resetModal();
      this.fetchingData = true;
      customersManager
        .fetchTypes()
        .then(res => {
          this.customer_types = res.types.map(x => {
            x.name = this.$t(x.type);
            return x;
          });
          customersManager
            .fetchCategories()
            .then(res => {
              console.log(res);
              this.customer_categories = res.items;
              customersManager
                .fetchAddressType()
                .then(res => {
                  this.address_types = res.types.map(x => {
                    x.locale_string = x.name;
                    x.name = this.$t(x.name);
                    return x;
                  });
                  customersManager
                    .fetchVatExemptions()
                    .then(res => {
                      this.vat_exemptions = [
                        { id: 0, value: this.$t("gbl-none-female") },
                        ...res.exemptions
                      ];
                      if (!customer.reference_color)
                        customer.reference_color = "#6d2533";
                      this.colorPickerColor = customer.reference_color;
                      this.fetchingData = true;
                      priceListsManager
                        .fetchPriceListsNames()
                        .then(res => {
                          this.pricelists = [
                            { id: 0, name: this.$t("gbl-none-male") },
                            ...res
                          ];
                          this.editingCustomer = editing;
                          this.fetchingAddresses = true;

                          if (editing) {
                            this.customer = Object.assign({}, customer);

                            this.fetchAddresses(customer.id, true)
                              .then(res => {
                                this.customer.addresses = [
                                  ...res.invoice_addresses,
                                  ...res.shipment_addresses
                                ];
                                this.fetchingAddresses = false;
                                this.fetchingData = false;
                              })
                              .catch(err => {
                                this.fetchingAddresses = false;
                                this.fetchingData = false;
                                console.log(err);
                              });
                            requestAnimationFrame(() => {
                              if (this.$refs.obs) {
                                this.$refs.obs.validate();
                              }
                            });
                          } else {
                            this.fetchingData = false;
                            this.fetchingAddresses = false;
                            this.customer = {
                              id: null,
                              type: 1,
                              business_name: "",
                              first_name: "",
                              last_name: "",
                              addresses: [],
                              price_list_id: 0,
                              reference_color: "#6d2533",
                              vat_exemption_id: 0,
                              category_id: 1
                            };

                            requestAnimationFrame(() => {
                              if (this.$refs.obs) this.$refs.obs.reset();
                            });
                          }
                        })
                        .catch(err => {
                          this.fetchingData = false;
                          console.log(err);
                        });
                    })
                    .catch(err => {
                      console.log(err);
                      this.fetchingData = false;
                    });
                })
                .catch(err => {
                  console.log(err);
                  this.fetchingData = false;
                });
            })
            .catch(err => {
              console.log(err);
              this.fetchingData = false;
            });
        })
        .catch(err => {
          console.log(err);
          this.fetchingData = false;
        });
    }
  }
};
</script>

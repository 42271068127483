<template>
<div v-if="!folderOnly || (folderOnly && isFolder)" class="no-select">
  <v-row :disabled="isFetching" class="folders-item" id="item">
      <v-col :style="colStyle" class="text-left folders-col">
      <div v-if="isFolder" class="folders-container">
      <span  @click="toggleItem" >
        <v-icon color="yellow darken-3" v-if="isOpen && item.isSelected">mdi-folder-open</v-icon>
        <v-icon color="#dddddd" v-if="isOpen && !item.isSelected">mdi-folder-open</v-icon>
        <v-icon color="#dddddd" v-if="!isOpen && !item.isSelected">mdi-folder</v-icon>
        <v-icon color="yellow darken-3" v-if="!isOpen && item.isSelected">mdi-folder</v-icon>
        </span>
      <span @click="selectItem"> {{ item.root ? "Storage" : item.name }} </span> 
      <v-progress-circular v-if="isFetching" :indeterminate="true" :rotate="0" :size="16" :width="2" color="primary"></v-progress-circular>        
      </div>
      <div v-if="!isFolder" :class="getClass()" >
      <span @click="selectFile" >
        <img :src="getIconPath" class="file_icon no-select">
        </span>
      <span @click="selectFile"> {{ item.name }} </span> 
      </div>
      </v-col>
    </v-row>
    <tree-item :folderOnly="folderOnly" :fileSelectCallback="fileSelectCallback" :selectCallback="selectCallback" :fetchCallback="fetchCallback" @itemSelected="onChildItemSelected" v-show="isOpen" name="FoldersItem" class="item" v-for="(child, index) in item.children" :indent="indent+1" :key="index" :item="child"> </tree-item>
    </div>
</template>

<style scoped>

.folders-col {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.folders-item {
  background-color:white;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 0px;

}

.folders-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.file_icon {
    width: 24px;
    height: 24px;
    vertical-align:middle;   
}

.file-item {
    padding-left:5px;
    padding-right:5px;
    background-color:white;
    cursor: pointer;
    display: flex;
    align-items: center;
   
}

.file-item-selected {
    padding-left:5px;
    padding-right:5px;
    background-color:#fda55a;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
}

.file-item:hover {
    background-color:#eeeeee;
}
</style>

<script>

const path = require('path');

export default {
  name: "tree-item",

  props: {
    item: Object,
    indent : Number,
    fetchCallback : Function,
    selectCallback : Function,
    fileSelectCallback : Function,
    disabled : Boolean,
    folderOnly : Boolean
  },
  
  data: function () {
    return {
      isOpen: false,
      isFetching : false,
       supportedExtensions : [
          'afx','ai','avi','css','dll','doc','dwg',
          'eps','flv','gif','html','iso','jpg','js',
          'mp3','mp4','pdf','png','ppt','psd','raw',
          'tiff','xls','xlsx','zip'
        ],

        defaultExtension : 'default',
    }
  },

  updated() {
    this.item.domItem = this;
  },

  computed: {
    isFolder: function () {
      return this.item.children && (this.item.children instanceof Array)
    },

    colStyle : function() {
      return {
        "padding-left" : (this.indent * 10) + 'px',
      }
    },

    getIconPath() {
        let extension = path.extname(this.item.name);
        extension = extension.replace(".","");
        if (this.supportedExtensions.indexOf(extension) >= 0) {
            return require( "../assets/files/" + extension + ".png");
        } else {
            return require( "../assets/files/" + this.defaultExtension + ".png");
        }
    }
  
  },
  
  methods: {
    fetch: function() {
      this.isFetching = true;
      this.fetchCallback({ item : this.item , context: this, state : this.isOpen}, this.onToggleItemCompleted);
    },

    toggleItem : function () {
       if (!this.disabled) {
          this.isOpen = !this.isOpen;
          this.fetch();
       }
    },

     selectItem : function () {
       if (!this.disabled) {
          this.isOpen = true;
          this.fetch();
       }
    },

    selectFile : function() {
      if (this.fileSelectCallback) this.fileSelectCallback(this);
    },

    onToggleItemCompleted : function() {
      this.isFetching = false;
      this.selectCallback(this);
    },

    onChildItemSelected : function(item) {
      this.selectCallback(item);
    },

     getClass() {
          if (this.item.isSelected) return "file-item-selected";
          else return "file-item";
      },
  }
}
</script>

<template>
  <div>
    <messageBox ref="msgBox" />
    <ShipmentEditor ref="shipmentEditor" />

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"
            ><v-icon large>mdi-dropbox</v-icon></v-col
          >
          <v-col
            align="center"
            cols="11"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Gestione spedizioni</v-col
          >
        </v-row>
      </template>

      <v-card-text>
        <div style="height:20px" />
        <v-row class="text-center" align="center" justify="center"
          ><v-btn
            v-if="$store.state.hasRight('262')"
            v-on:click="addShipment()"
            color="primary"
            dark
            >Aggiungi spedizione</v-btn
          >
        </v-row>

        <v-data-table
          :items-per-page="perPage"
          :must-sort="true"
          :headers="shipmentsHeaders"
          :items="shipments"
          :options.sync="shipmentsOptions"
          :server-items-length="totalShipments"
          :loading="loadingShipments"
          :footer-props="dataTableFooterOptions"
          item-key="id"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('263')"
                  x-small
                  text
                  icon
                  color="secondary"
                  @click.stop="editShipment(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>Modifica spedizione</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('264')"
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteShipment(item.id)"
                  v-on="on"
                  ><v-icon>mdi-delete</v-icon></v-btn
                ></template
              ><span>Rimuovi spedizione</span></v-tooltip
            >
          </template>

          <template v-slot:item.reference_dates="{ item }">
            {{ item.start_date | toLocaleDate }} -
            {{ item.end_date | toLocaleDate }}
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </div>
</template>

<script>
import ShipmentEditor from "../components/ShipmentEditor";

import staticData from "../apis/staticData";
import subscriptionsManager from "../apis/subscriptions";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      shipments: [],
      totalShipments: 0,
      loadingShipments: false,

      perPage: staticData.defaultPerPage,
      shipmentsOptions: {
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: staticData.defaultPerPage
      },

      shipmentsHeaders: [],
      fetchingShipments: false
    };
  },

  components: {
    messageBox,
    ShipmentEditor
  },

  mounted() {
    this.shipmentsHeaders = [
      {
        text: "Nome spedizione",
        value: "name"
      },
      {
        text: "Descrizione",
        value: "description"
      },

      {
        text: "Periodo di riferimento",
        value: "reference_dates"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];
  },

  watch: {
    shipmentsOptions: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadShipments(true);
        }, 300);
      },
      deep: true
    }
  },

  methods: {
    addShipment() {
      let shipment = {
        name: "Nuova spedizione",
        description: "Descrizione",
        start_date: new Date().toISOString(),
        end_date: new Date().toISOString()
      };
      this.$refs.shipmentEditor
        .show(shipment, false)
        .then(shipment => {
          subscriptionsManager
            .addShipment(shipment)
            .then(() => {
              this.reloadShipments(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editShipment(item) {
      let shipment = Object.assign({}, item);
      this.$refs.shipmentEditor
        .show(shipment, true)
        .then(shipment => {
          subscriptionsManager
            .editShipment(shipment)
            .then(() => {
              this.reloadShipments(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    deleteShipment(id) {
      this.$refs.msgBox
        .show(
          "Vuoi davvero confermare la cancellazione della spedizione ?",
          "Conferma operazione"
        )
        .then(() => {
          subscriptionsManager
            .removeShipment(id)
            .then(() => {
              this.reloadShipments(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    reloadShipments: function(showLoader) {
      this.fetchShipments(showLoader)
        .then(data => {
          this.shipments = data.items;
          this.totalShipments = data.totalCount;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchShipments(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.shipmentsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        subscriptionsManager
          .fetchShipments(ctx)
          .then(result => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    }
  }
};
</script>

a<template>
  <div>
    <messageBox ref="msgBox" />

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"><v-icon large>mdi-bottle-wine</v-icon></v-col>
          <v-col align="center" cols="11" class="pa-0 card-title-text" style="font-size: 20px">Gestione prodotti</v-col>
        </v-row>
      </template>

      <v-card-text>
        <v-row v-if="fetchingFilters"><v-col>
            <v-progress-circular :indeterminate="true" :rotate="0" :size="32" :width="4"
              color="primary"></v-progress-circular> </v-col></v-row>

        <v-row v-if="!fetchingFilters">
          <v-col>
            <v-text-field outlined dense v-model="filter" append-icon="mdi-magnify" class="mr-auto ml-1 pl-1"
              label="Ricerca" hide-details />
          </v-col>
          <v-col>
            <v-select v-model="filters.categoryFilter" label="Categoria" required outlined dense :items="categories"
              item-value="id" item-text="name"></v-select>
          </v-col>
          <v-col>
            <v-select v-model="filters.warehouseFilter" label="Categoria" required outlined dense :items="warehouses"
              item-value="id" item-text="name"></v-select>
          </v-col>
          <v-col>
            <v-select class="minfield" outlined dense label="Periodo" ref="period" v-model="filters.filterByPeriod"
              :items="getPeriodFilters" item-text="name" item-value="id">
            </v-select>
          </v-col>
          <v-col v-if="filters.filterByPeriod == 13">
            <v-dialog ref="dialog_start_date" v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart" width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field dense outlined v-model="start_date_formatted" label="Data iniziale" readonly clearable
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker @input="
                $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                " v-model="filters.filterByCustomDateStart" scrollable :first-day-of-week="1"
                :locale="$store.state.user.locale">
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col v-if="filters.filterByPeriod == 13">
            <v-dialog ref="dialog_end_date" v-model="date_end_modal" :return-value.sync="filters.filterByCustomDateEnd"
              width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field dense outlined v-model="end_date_formatted" label="Data finale" readonly clearable
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker @input="
                $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                " v-model="filters.filterByCustomDateEnd" scrollable :first-day-of-week="1"
                :locale="$store.state.user.locale">
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-data-table :items-per-page="perPage" :must-sort="true" :headers="headers" :items="movements"
          :options.sync="options" :server-items-length="totalMovements" :loading="loadingMovements"
          :footer-props="dataTableFooterOptions" item-key="id">
          <template v-slot:item.user_id="{ item }">
            <v-avatar size="48px" v-if="checkGravatar(item) && getGravatar(item)">
              <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
            </v-avatar>

            <v-avatar v-else-if="getProfileLetters(item)" color="blue" size="48px">
              <span class="profile-text">{{ getProfileLetters(item) }}</span>
            </v-avatar>
          </template>

          <template v-slot:item.operation="{ item }">
            <v-icon v-if="item.amount > 0" color="green">mdi-arrow-up</v-icon>
            <v-icon v-if="item.amount < 0" color="red">mdi-arrow-down</v-icon>
            <v-icon @click="downloadItem(item.file)" v-if="item.file" color="orange">mdi-attachment</v-icon>
            {{ item.lot }}
          </template>
          <template v-slot:item.operation_date="{ item }">
            {{ item.operation_date | toLocaleDateTime }}
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </div>
</template>

<script>
import staticData from "../apis/staticData";
import productsManager from "../apis/products";
import messageBox from "../components/MessageBox";
import AvatarServices from "../services/avatars";

export default {
  data() {
    return {
      date_start_modal: false,
      date_end_modal: false,

      fetchingFilters: false,

      dataTableFooterOptions: staticData.dataTableFooterOptions,
      movements: [],
      totalMovements: 0,
      filter: null,
      loadingMovements: false,
      perPage: staticData.defaultPerPage,
      options: {
        sortBy: ["operation_date"],
        sortDesc: [true],
        itemsPerPage: staticData.defaultPerPage
      },
      filters: {
        categoryFilter: null,
        warehouseFilter: null,
        filterByPeriod: 5,
        filterByCustomDateEnd: null,
        filterByCustomDateStart: null
      },

      headers: [],
      categories: [],
      warehouses: []
    };
  },

  computed: {
    start_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      }
    },

    end_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      }
    },

    getPeriodFilters: {
      get: function () {
        return staticData.filtersPeriods();
      }
    }
  },

  components: {
    messageBox
  },

  mounted() {
    this.fetchingFilters = true;
    this.fetchWarehouses().then(() => {
      this.headers = [
        {
          text: "Data",
          value: "operation_date"
        },
        {
          text: "Utente",
          value: "user_id",
          align: "center",
          sortable: false
        },
        {
          text: "Nome vino",
          value: "name"
        },
        {
          text: "Cantina",
          value: "structure"
        },
        {
          text: "Operazione",
          value: "operation",
          align: "center",
          sortable: false
        }
      ];

      for (let x of this.warehouses) {
        if (x.id) {
          this.headers.push({
            text: x.name,
            align: "center",
            sortable: false,
            value: "warehouse_amount_" + x.id
          });

          this.headers.push({
            text: "",
            align: "center",
            sortable: false,
            value: "warehouse_value_" + x.id
          });
        }
      }
      this.headers.push({
        text: "",
        align: "center",
        sortable: false,
        value: "actions"
      });

      this.fetchCategories()
        .then(() => {
          this.fetchingFilters = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingFilters = false;
        });
    });
  },

  watch: {
    options: {
      handler() {
        clearTimeout(this._movementsDebounceTimer);
        this._movementsDebounceTimer = setTimeout(() => {
          this.reloadMovements(true);
        }, 300);
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._movementsDebounceTimer);
        this._movementsDebounceTimer = setTimeout(() => {
          this.reloadMovements(true);
        }, 300);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._movementsDebounceTimer);
        this._movementsDebounceTimer = setTimeout(() => {
          this.reloadMovements(true);
        }, 300);
      },
      deep: true
    }
  },

  methods: {
    downloadItem(item) {
      productsManager.downloadFile(item);
    },

    buildGravatar: function (item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function (item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getGravatar: function (item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function (item) {
      return item.name;
    },

    getProfileLetters: function (user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    fetchCategories(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.productsOptions);

        productsManager
          .fetchCategories(ctx)
          .then(result => {
            this.categories = [
              { id: null, name: "Tutte le categorie" },
              ...result.items
            ];
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    },

    reloadMovements: function (showLoader) {
      this.fetchMovements(showLoader)
        .then(data => {
          this.movements = data.items;
          this.totalMovements = data.totalCount;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchWarehouses() {
      return new Promise((resolve, reject) => {
        productsManager
          .fetchWarehouses()
          .then(result => {
            this.warehouses = [
              { id: null, name: "Tutti i magazzini" },
              ...result.warehouses
            ];
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    fetchMovements(showLoader) {
      if (showLoader) this.loadingMovements = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        productsManager
          .fetchMovements(ctx)
          .then(result => {
            if (showLoader) this.loadingMovements = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loadingMovementsù = false;
            reject(err);
          });
      });
    }
  }
};
</script>

<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="640"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col
                  align="center"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ title }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <span v-if="text">{{ text }}</span>
              <ValidationObserver v-if="required" ref="obs" v-slot="{}">
                <ValidationProvider
                  v-if="label"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="value"
                    :label="label"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>

                <div v-if="label && select_label" style="height: 20px" />

                <ValidationProvider
                  v-if="select_label"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-select
                    outlined
                    dense
                    v-model="select_value"
                    :label="select_label"
                    :items="select_items"
                    item-text="name"
                    item-value="id"
                    required
                  ></v-select>
                  <v-btn
                    v-if="required"
                    :disabled="(required && !value && !select_value) || !valid"
                    width="120"
                    @click="confirm()"
                    :color="okvariant"
                  >
                    {{ $t("gbl-ok") }}
                  </v-btn>

                  <v-btn
                    v-if="required"
                    width="120"
                    @click="dismiss()"
                    :color="cancelvariant"
                    secondary
                  >
                    {{ $t("msgbox-cancel") }}
                  </v-btn>
                </ValidationProvider>
              </ValidationObserver>

              <v-text-field
                outlined
                dense
                v-if="!required && label"
                v-model="value"
                class="ml-auto"
                :label="label"
              />
              <v-select
                outlined
                dense
                v-if="!required && select_label"
                v-model="select_value"
                :label="select_label"
                :items="select_items"
                item-text="name"
                item-value="id"
                required
              ></v-select>
              <v-btn
                v-if="!required"
                :disabled="required && !value && !select_value"
                width="120"
                @click="confirm()"
                :color="okvariant"
              >
                {{ $t("gbl-ok") }}
              </v-btn>

              <v-btn
                v-if="!required"
                width="120"
                @click="dismiss()"
                :color="cancelvariant"
                secondary
              >
                {{ $t("msgbox-cancel") }}
              </v-btn>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "",
      label: "",
      value: "",
      select_value: null,
      text: null,
      initial_value: "",
      string_state: true,
      context: null,
      required: true,
      mustChange: false,
      okvariant: "primary",
      cancelvariant: "secondary",
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      select_label: null,
      select_items: []
    };
  },

  props: {
    showClose: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    ValidationObserver,
    ValidationProvider
  },

  methods: {
    show(
      title,
      label,
      text,
      initial_value,
      select_label,
      select_initial_value,
      select_items,
      context = null,
      required = true,
      mustChange = true,
      okvariant = "primary",
      cancelvariant = "secondary"
    ) {
      return new Promise((resolve, reject) => {
        this.value = initial_value;
        this.title = title;
        this.label = label;
        this.text = text;
        this.select_label = select_label;
        this.select_items = select_items;
        this.select_value = select_initial_value;
        this.initial_value = initial_value;
        this.okvariant = okvariant;
        this.cancelvariant = cancelvariant;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.required = required;
        this.context = context;
        this.mustChange = mustChange;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      if (
        !this.required ||
        (this.value && this.value.length > 0) ||
        this.select_value
      ) {
        if (
          (this.value != this.initial_value &&
            this.select_value != this.select_initial_value) ||
          !this.mustChange
        ) {
          this.openDialog = false;
          this.dialogResolve({
            context: this.context,
            value: { text: this.value, select: this.select_value }
          });
        }
      }
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>

<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <messageBox ref="msgBox" />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-dropbox</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 20px"
                  >{{
                    editing ? "Modifica spedizione" : "Aggiungi spedizione"
                  }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row>
                  <v-col>
                    <v-card elevation="2" outlined>
                      <v-card-title>
                        Proprietà spedizione
                      </v-card-title>
                      <v-card-text>
                        <v-form class="mt-5" ref="user-editor-form">
                          <ValidationProvider
                            :immediate="true"
                            rules="required"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              v-model="shipment.name"
                              label="Nome"
                              prepend-icon="mdi-dropbox"
                              :error-messages="errors"
                              :success="valid"
                              required
                            ></v-text-field>
                          </ValidationProvider>
                          <ValidationProvider
                            :immediate="true"
                            rules="required"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              v-model="shipment.description"
                              label="Descrizione"
                              prepend-icon="mdi-format-align-justify"
                              :error-messages="errors"
                              :success="valid"
                              required
                              :immediate="true"
                            ></v-text-field>
                          </ValidationProvider>

                          <v-dialog
                            ref="dialog_start_date"
                            v-model="date_start_modal"
                            :return-value.sync="date_start"
                            width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors, valid }"
                                :immediate="true"
                              >
                                <v-text-field
                                  v-model="start_date_formatted"
                                  label="Data di inizio"
                                  prepend-icon="mdi-calendar-multiple"
                                  :error-messages="errors"
                                  :success="valid"
                                  required
                                  v-on="on"
                                  clearable
                                  readonly
                                  :immediate="true"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              @input="$refs.dialog_start_date.save(date_start)"
                              v-model="date_start"
                              scrollable
                            >
                            </v-date-picker>
                          </v-dialog>

                          <v-dialog
                            ref="dialog_end_date"
                            v-model="date_end_modal"
                            :return-value.sync="date_end"
                            width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors, valid }"
                                :immediate="true"
                              >
                                <v-text-field
                                  v-model="end_date_formatted"
                                  readonly
                                  clearable
                                  v-on="on"
                                  label="Data di fine"
                                  prepend-icon="mdi-calendar-multiple"
                                  :error-messages="errors"
                                  :success="valid"
                                  :immediate="true"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              @input="$refs.dialog_end_date.save(date_end)"
                              v-model="date_end"
                              scrollable
                            >
                            </v-date-picker>
                          </v-dialog>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="text-center">
                  <v-col>
                    <v-btn
                      :disabled="invalid || !validated"
                      width="120"
                      @click="confirm()"
                      class="ma-2"
                      color="primary"
                      >Conferma</v-btn
                    >
                    <v-btn
                      dark
                      width="120"
                      @click="dismiss()"
                      class="ma-2"
                      color="secondary"
                      >Annulla</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      shipment: null,
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      editing: false,
      date_start_modal: false,
      date_end_modal: false,
      date_shipment_modal: false,
      date_start: null,
      date_end: null,
      date_shipment: null,
      loadingMissingProducts: false,
      missingProduct: null,
      missingProductSearch: null,
      missingProducts: [],
      products: [],
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    messageBox,
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.date_start);
      },
      set: function(v) {
        this.date_start = v;
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.date_end);
      },
      set: function(v) {
        this.date_end = v;
      },
    },
  },

  methods: {
    show(shipment, editing) {
      return new Promise((resolve, reject) => {
        this.shipment = shipment;
        this.date_start = this.shipment.start_date.substr(0, 10);
        this.date_end = this.shipment.end_date.substr(0, 10);

        this.editing = editing;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.shipment.start_date = new Date(
        Date.parse(this.date_start + "T12:00:00.000Z")
      );
      this.shipment.start_date = this.shipment.start_date.getTime();

      this.shipment.end_date = new Date(
        Date.parse(this.date_end + "T12:00:00.000Z")
      );
      this.shipment.end_date = this.shipment.end_date.getTime();

      this.dialogResolve(this.shipment);
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>

import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";
import store from "../store";

let orders = new Object({
  addWarehouseAssignment: (customer_id, product_id, amount) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/addWarehouseAssignment",
        data: {
          customer_id: customer_id,
          product_id: product_id,
          amount: amount
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeWarehouseAssignment: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/removeWarehouseAssignment",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  copyInvoiceToManualOrder: (dest_order_id, doc_num, doc_year, path) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/copyInvoiceToManualOrder",
        data: {
          backend: store.state.backend,
          dest_order_id: dest_order_id,
          doc_num: doc_num,
          doc_year: doc_year,
          path: path
        },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  copyInvoiceToOnetimeOrder: (dest_order_id, doc_num, doc_year, path) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/copyInvoiceToOnetimeOrder",
        data: {
          backend: store.state.backend,
          dest_order_id: dest_order_id,
          doc_num: doc_num,
          doc_year: doc_year,
          path: path
        },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchCompanies: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/fetchCompanies",
        data: { backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.companies);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOrders: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/fetch",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.orders,
            totalCount: resp.data.responseData.count,
            notExportedCount: resp.data.responseData.notExportedCount,
            noticeTickets: resp.data.responseData.noticeTickets,
            warningTickets: resp.data.responseData.warningTickets,
            errorTickets: resp.data.responseData.errorTickets
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOnetimeOrders: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/onetimeFetch",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.orders,
            totalCount: resp.data.responseData.count,
            notExportedCount: resp.data.responseData.notExportedCount,
            noticeTickets: resp.data.responseData.noticeTickets,
            warningTickets: resp.data.responseData.warningTickets,
            errorTickets: resp.data.responseData.errorTickets
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchWarehouseAssignments: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/fetchWarehouseAssignments",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.assignments,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchWarehouseOrders: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/fetchWarehouseOrders",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.orders,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addWarehouseOrder: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/addWarehouseOrder",
        data: { order: item },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeWarehouseOrder: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/removeWarehouseOrder",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateWarehouseOrder: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/editWarehouseOrder",
        data: { order: item },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchWarehouseOrderCart: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/fetchWarehouseOrderCart",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  changeWarehouseOrderStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/changeWarehouseOrderStatus",
        data: { id: id, status: status },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addOrder: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/add",
        data: { order: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  editOrder: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/edit",
        data: { order: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  editOnetimeOrder: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/editOnetime",
        data: { order: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateItemInvoice: (item, path) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/invoice",
        data: { order_id: item, path: path, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateOnetimeItemInvoice: (item, path) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/onetimeInvoice",
        data: { order_id: item, path: path, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeOrder: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/remove",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeOnetimeOrder: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/removeOnetime",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  flagOrderShipped: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/shipped",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  flagOnetimeOrderShipped: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/shippedOnetime",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setShipmentDate: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/setShipmentDate",
        data: { id: id, backend: store.state.backend, date: date },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setOnetimeShipmentDate: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/setOnetimeShipmentDate",
        data: { id: id, backend: store.state.backend, date: date },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setOrderDate: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/setOrderDate",
        data: { id: id, backend: store.state.backend, date: date },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setOnetimeOrderDate: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/setOnetimeOrderDate",
        data: { id: id, backend: store.state.backend, date: date },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setOnetimeExportDate: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/setOnetimeExportDate",
        data: { id: id, backend: store.state.backend, date: date },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setDeliveryDate: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/setDeliveryDate",
        data: { id: id, backend: store.state.backend, date: date },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setOnetimeDeliveryDate: (id, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/setOnetimeDeliveryDate",
        data: { id: id, backend: store.state.backend, date: date },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  downloadWarehouseOrderResume: orderID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/downloadWarehouseOrderResume?token=" +
          encodeURI(token) +
          "&orderID=" +
          orderID
      );
      link.setAttribute("target", "_blank");
      let filename = "order_" + orderID + ".pdf";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadOrderMOS: orderID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/downloadOrderMOS?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          "&orderID=" +
          orderID
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" +
        store.state.backend +
        "_order_mos_export_" +
        orderID +
        ".pdf";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadOnetimeOrderMOS: orderID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/downloadOnetimeOrderMOS?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          "&orderID=" +
          orderID
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" +
        store.state.backend +
        "_order_mos_export_" +
        orderID +
        ".pdf";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  }
});

export default orders;

import axios from 'axios'
import config from '../config'

let applications = {
   
    fetchApplications : (ctx) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/list", data: {ctx : ctx }, method: 'POST' })
            .then(resp => {
                resolve({ items : resp.data.responseData.applications , totalCount : resp.data.responseData.count });
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    fetchApplicationsAndPermissions : () => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/fetchApplicationsAndPermissions", data: { }, method: 'POST' })
            .then(resp => {
                resolve({ items : resp.data.responseData.applications  });
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    addApplication : (app) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/add", data: { app : app }, method: 'POST' })
            .then((resp) => {
                resolve(resp.data.responseData.appId);
            })
            .catch((err) => {
                reject(err);
            })
        });
    },

    updateApplication : (app) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/update", data: { app : app }, method: 'POST' })
            .then(() => {
                resolve();
            })
            .catch((err) => {
                reject(err);
            })
        });
    },

    removeApplication : (id) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/delete", data: { id : id  }, method: 'POST' })
            .then(() => {
                resolve();
            })
            .catch((err) => {
                reject(err);
            })
        });
    },

    fetchPermissions : (id) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/permissions", data: {id : id }, method: 'POST' })
            .then(resp => {
                resolve({ items : resp.data.responseData.permissions , totalCount : resp.data.responseData.count });
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    addPermission : (id,permission) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/addPermission", data: { id :id , permission : permission }, method: 'POST' })
            .then((resp) => {
                resolve(resp.data.responseData.permissionId);
            })
            .catch((err) => {
                reject(err);
            })
        });
    },

    updatePermission : (id,permission) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/updatePermission", data: { id : id, permission : permission }, method: 'POST' })
            .then(() => {
                resolve();
            })
            .catch((err) => {
                reject(err);
            })
        });
    },

    removePermission : (id) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/apps/deletePermission", data: { id : id  }, method: 'POST' })
            .then(() => {
                resolve();
            })
            .catch((err) => {
                reject(err);
            })
        });
    },

    

}

export default applications;

<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <SetString ref="renamePermission" />
    <MessageBox ref="msgBox" />
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-application</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("appeditor-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form class="mt-5" ref="app-editor-form">
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <ValidationProvider
                  :immediate="editingApplication"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="app.name"
                    :label="$t('appeditor-hint-name')"
                    prepend-icon="mdi-circle-edit-outline"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider
                  :immediate="editingApplication"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="app.description"
                    :label="$t('appeditor-hint-description')"
                    prepend-icon="mdi-circle-edit-outline"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>

                <v-btn :disabled="!editingApplication" v-on:click="addPermission()" color="primary">{{
                  $t("appeditor-add-right")
                }}</v-btn>
                <div style="height:10px" />

                <v-data-table
                  class="grid-table"
                  :items-per-page="perPage"
                  :must-sort="true"
                  :headers="headers"
                  :items="permissions"
                  :options.sync="options"
                  :server-items-length="totalPermissions"
                  :loading="fetchingData"
                  :footer-props="dataTableFooterOptions"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          x-small
                          text
                          icon
                          color="secondary"
                          @click.stop="editPermission(item)"
                          v-on="on"
                          ><v-icon>mdi-qrcode-edit</v-icon></v-btn
                        ></template
                      ><span>{{ $t("appmng-modify-app") }}</span></v-tooltip
                    >

                    <v-tooltip bottom
                      ><template v-slot:activator="{ on }"
                        ><v-btn
                          x-small
                          text
                          icon
                          color="error"
                          @click.stop="deletePermission(item)"
                          v-on="on"
                          ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                        ></template
                      ><span>{{ $t("appmng-remove-app") }}</span></v-tooltip
                    >
                  </template>
                </v-data-table>

                <v-btn
                  width="120"
                  :disabled="invalid || !validated"
                  v-on:click="confirm()"
                  color="orange"
                  >{{ $t("gbl-ok") }}</v-btn
                >
                <v-btn width="120" v-on:click="cancel()" color="primary">{{
                  $t("gbl-cancel")
                }}</v-btn>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import SetString from "./SetString";
import MessageBox from "./MessageBox.vue";

import applicationManager from "../apis/applications";
import staticData from "../apis/staticData";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      app: {},
      editingApplication: false,
      fetchingData: false,

      dataTableFooterOptions: staticData.dataTableFooterOptions,
      permissions: [],
      totalPermissions: 0,
      options: {
        sortBy: ["ID"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    SetString,
    MessageBox,
  },

  props: ["value"],

  watch: {
    options: {
      handler() {
        this.reloadPermissions(true);
      },
      deep: true,
    },
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("appeditor-permissions-tbl-id"),
        align: "left",
        sortable: true,
        value: "id",
      },
      {
        text: this.$t("appeditor-permissions-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  methods: {
    editPermission: function(item) {
      this.$refs.renamePermission
        .show(
          this.$t("appeditor-permissions-title"),
          this.$t("appeditor-permissions-hint"),
          this.$t("appeditor-permissions-text"),
          item.name,
          null,
          true,
          false
        )
        .then((res) => {
          applicationManager
            .updatePermission(item.id, res.value)
            .then(() => {
              this.reloadPermissions();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    deletePermission: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("appeditor-permissions-delete-text"),
          this.$t("appeditor-permissions-delete-title")
        )
        .then(() => {
          applicationManager
            .removePermission(item.id)
            .then(() => {
              this.reloadPermissions();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    addPermission: function() {
      this.$refs.renamePermission
        .show(
          this.$t("appeditor-permissions-title"),
          this.$t("appeditor-permissions-hint"),
          this.$t("appeditor-permissions-text"),
          this.$t("appeditor-permissions-default"),
          null,
          true,
          false
        )
        .then((res) => {
          applicationManager
            .addPermission(this.app.id, res.value)
            .then(() => {
              this.reloadPermissions();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    },

    reloadPermissions: function(showLoader) {
      this.permissions = [];
      this.totalPermissions = 0;
      this.fetchPermissions(showLoader)
        .then((data) => {
          this.permissions = data.items;
          this.totalPermissions = data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchPermissions(showLoader) {
      if (showLoader) this.fetchingData = true;
      return new Promise((resolve, reject) => {
        applicationManager
          .fetchPermissions(this.app.id)
          .then((result) => {
            if (showLoader) this.fetchingData = false;
            resolve(result);
          })
          .catch((err) => {
            if (showLoader) this.fetchingData = false;
            console.log(err);
            reject();
          });
      });
    },

    confirm: function() {
      this.handleSubmit();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("applicationConfigured", {});
      //this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    handleSubmit() {
      this.$refs.obs.reset();
      this.$emit("applicationConfigured", {
        editingApplication: this.editingApplication,
        app: this.app,
      });
    },

    resetModal() {
      this.app.name = "";
      this.app.description = "";
    },

    setModalApp(app, editing) {
      this.app = Object.assign({}, app);
      this.editingApplication = editing;
      if (!this.editingApplication) this.resetModal();
      this.reloadPermissions();

      if (editing) {
        requestAnimationFrame(() => {
          if (this.$refs.obs) {
            this.$refs.obs.validate();
          }
        });
      } else {
        requestAnimationFrame(() => {
          if (this.$refs.obs) this.$refs.obs.reset();
        });
      }
    },
  },
};
</script>

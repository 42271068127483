<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <messageBox ref="msgBox" />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1"><v-icon large>mdi-dropbox</v-icon></v-col>
                <v-col
                  align="center"
                  cols="10"
                  class="card-title-text"
                  style="font-size: 20px"
                  >{{
                    ticket
                      ? "Dettaglio ticket N." + ticket.id
                      : "Caricamento..."
                  }}</v-col
                >
                <v-col cols="1">
                  <v-btn icon>
                    <v-icon @click="dismiss" large>mdi-close</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-progress-circular
                v-if="loadingTicket"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>

              <div v-if="!loadingTicket && ticket">
                <h2>{{ ticket.title }}</h2>

                <p></p>
                <h4>
                  {{ "Aperto il " }}
                  {{ ticket.creation_date | toLocaleDateTime }} da
                  {{ ticket.user }}
                </h4>
                <v-timeline dense>
                  <v-timeline-item
                    large
                    v-for="item in ticket.items"
                    :key="item.id"
                  >
                    <template v-slot:icon>
                      <v-avatar
                        size="48px"
                        v-if="checkGravatar(item) && getGravatar(item)"
                      >
                        <v-img
                          :src="getGravatar(item)"
                          :alt="getUsername(item)"
                        />
                      </v-avatar>

                      <v-avatar
                        v-else-if="getProfileLetters(item)"
                        color="blue"
                        size="48px"
                      >
                        <span class="profile-text">{{
                          getProfileLetters(item)
                        }}</span>
                      </v-avatar>
                    </template>
                    <template v-slot:opposite>
                      <span>{{ item.user }}</span>
                    </template>
                    <v-card class="elevation-2">
                      <v-card-title>
                        <h6>{{ item.user }}</h6>
                        <h6>{{ item.creation_date | toLocaleDateTime }}</h6>
                      </v-card-title>
                      <v-card-text>{{ item.content }}</v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>

                <v-row>
                  <v-col>
                    <v-form class="mt-5" ref="user-editor-form">
                      <v-textarea
                        outlined
                        label="Descrizione"
                        v-model="content"
                      ></v-textarea>
                    </v-form>
                  </v-col>
                </v-row>

                <v-row class="text-center">
                  <v-col>
                    <v-btn
                      width="120"
                      style="color:white;"
                      :disabled="!$store.state.hasRight('286') || !content"
                      @click="confirm()"
                      class="ma-2"
                      color="primary"
                      >Conferma</v-btn
                    >
                    <v-btn
                      dark
                      width="120"
                      @click="dismiss()"
                      class="ma-2"
                      color="secondary"
                      >Annulla</v-btn
                    >

                    <v-btn
                      dark
                      width="120"
                      @click="closeTicket()"
                      class="ma-2"
                      :disabled="!$store.state.hasRight('285')"
                      color="red"
                      >Chiudi ticket</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import messageBox from "../components/MessageBox";
import ticketManager from "../apis/tickets";
import AvatarServices from "../services/avatars";

export default {
  data() {
    return {
      openDialog: false,
      editing: false,
      dialogResolve: null,
      dialogReject: null,
      ticket: null,
      loadingTicket: false,

      content: null
    };
  },

  components: {
    messageBox
  },

  methods: {
    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.user_id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    confirm() {
      this.dialogResolve({ id: this.ticket.id, content: this.content });
      this.openDialog = false;
    },

    dismiss() {
      this.dialogReject();
      this.openDialog = false;
    },

    closeTicket() {
      this.dialogResolve({ id: this.ticket.id, closed: true });
      this.openDialog = false;
    },

    show(id, key) {
      this.loadingTicket = true;
      this.openDialog = true;
      this.content = null;
      return new Promise((resolve, reject) => {
        this.dialogReject = reject;
        this.dialogResolve = resolve;
        ticketManager
          .fetchTicket(key, id)
          .then(res => {
            this.loadingTicket = false;
            this.ticket = res;
          })
          .catch(err => {
            this.loadingTicket = false;
            console.log(err);
          });
      });
    }
  }
};
</script>

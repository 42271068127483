<template>
  <v-container justify-center id="login" class="fill-height" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <v-icon>mdi-lock</v-icon>
              Reimposta password
            </div>
          </template>

          <v-card-text class="text-center">
            <ValidationObserver v-slot="{ invalid, validated }">
              <div class="container-logo-small-pages">
                <img
                  src="../assets/rimessa_logo_rosso.png"
                  alt="Roscioli Logo"
                />
              </div>

              <div v-if="!resetPasswordError && !resetSuccess">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors, valid }"
                  vid="password_confirm"
                >
                  <v-text-field
                    color="secondary"
                    :append-icon="view_password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (view_password = !view_password)"
                    :type="view_password ? 'password' : 'text'"
                    :error-messages="errors"
                    :success="valid"
                    v-model="password"
                    label="Nuova password"
                    prepend-icon="mdi-lock"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required|confirmed:password_confirm"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    color="secondary"
                    :append-icon="
                      view_password_confirm ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="
                      () => (view_password_confirm = !view_password_confirm)
                    "
                    :type="view_password_confirm ? 'password' : 'text'"
                    :error-messages="errors"
                    :success="valid"
                    v-model="repeat_password"
                    label="Ripeti password"
                    prepend-icon="mdi-lock"
                    required
                  />
                </ValidationProvider>
              </div>

              <div v-if="resetSuccess">
                <div style="height:10px"></div>
                <v-alert type="success" dense>
                  <span class="text-uppercase"
                    >Password cambiata con successo</span
                  >
                </v-alert>
              </div>

              <div v-if="resetPasswordError">
                <div style="height:10px"></div>
                <v-alert type="error" dense>
                  <span class="text-uppercase">{{ resetPasswordError }}</span>
                </v-alert>
              </div>
              <div style="height:10px"></div>
              <v-btn
                :disabled="invalid || !validated || resetting_password"
                color="success"
                @click="resetPassword()"
                v-if="!resetSuccess && !passwordSuccess && !resetPasswordError"
                >Reimposta password</v-btn
              >
              <v-btn color="success" to="login" v-if="resetSuccess"
                >Login</v-btn
              >
              <v-btn
                color="red darken-4"
                dark
                v-if="resetPasswordError"
                to="recovery"
                >Ripeti procedura</v-btn
              >
            </ValidationObserver>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row></v-container
  >
</template>

<script>
import userManager from "../apis/users";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      view_password_confirm: String,
      view_password: String,
      password: "",
      repeat_password: "",
      resetting_password: false,
      passwordSuccess: false,
      resetPasswordError: null,
      resetInProgress: false,
      resetSuccess: false,
    };
  },

  mounted() {
    this.resetToken = this.$route.query.token;
    this.password = "";
    this.repeat_password = "";
    this.resetting_password = false;
    this.passwordSuccess = false;
    this.resetPasswordError = null;
    this.resetInProgress = false;
    this.resetSuccess = false;
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  methods: {
    resetPassword: function() {
      this.resetInProgress = true;
      userManager
        .resetPassword(this.password, this.resetToken)
        .then((result) => {
          if (result.data.responseData.responseError) {
            this.resetInProgress = false;
            this.resetPasswordError = result.data.responseData.responseError;
          } else {
            this.resetInProgress = false;
            this.resetSuccess = true;
            this.resetPasswordError = null;
          }
        })
        .catch((err) => {
          this.resetInProgress = false;
          this.resetPasswordError = err;
        });
    },
  },
};
</script>

<template>
  <div>
    <messageBox ref="msgBox" />
    <setString ref="setDescription" />

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"
            ><v-icon large>mdi-database</v-icon></v-col
          >
          <v-col
            align="center"
            cols="11"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Gestione esportazioni</v-col
          >
        </v-row>
      </template>

      <v-card-text>
        <div style="margin:15px">
          <v-row>
            <v-col>
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.filterByShipmentId"
                outlined
                item-text="name"
                item-value="id"
                :items="shipments"
                label="Spedizione"
                hide-details
            /></v-col>
            <v-col>
              <v-select
                :disabled="fetchingFilters"
                class="minfield"
                outlined
                dense
                label="Periodo"
                ref="period"
                v-model="filters.filterByPeriod"
                :items="getPeriodFilters"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col v-if="filters.filterByPeriod == 13">
              <v-dialog
                ref="dialog_start_date"
                v-model="date_start_modal"
                :return-value.sync="filters.filterByCustomDateStart"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="fetchingFilters"
                    dense
                    outlined
                    v-model="start_date_formatted"
                    label="Data iniziale"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="
                    $refs.dialog_start_date.save(
                      filters.filterByCustomDateStart
                    )
                  "
                  v-model="filters.filterByCustomDateStart"
                  scrollable
                  :first-day-of-week="1"
                  :locale="$store.state.user.locale"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col v-if="filters.filterByPeriod == 13">
              <v-dialog
                ref="dialog_end_date"
                v-model="date_end_modal"
                :return-value.sync="filters.filterByCustomDateEnd"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="fetchingFilters"
                    dense
                    outlined
                    v-model="end_date_formatted"
                    label="Data finale"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="
                    $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                  "
                  v-model="filters.filterByCustomDateEnd"
                  scrollable
                  :first-day-of-week="1"
                  :locale="$store.state.user.locale"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </div>

        <v-data-table
          v-if="!fetchingFilters"
          :items-per-page="perPage"
          :must-sort="true"
          :headers="headers"
          :items="exports"
          :options.sync="exportsOptions"
          :server-items-length="totalExports"
          :loading="loadingExports"
          :footer-props="dataTableFooterOptions"
          item-key="id"
        >
          <template v-slot:item.description="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.isAdmin()"
                  x-small
                  text
                  icon
                  color="secondary"
                  @click.stop="setDescription(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>Modifica descrizione</span></v-tooltip
            >
            {{ item.description }}
          </template>

          <template v-slot:item.date="{ item }">
            {{ item.date | toLocaleDateTime }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('269')"
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="exportMos(item)"
                  v-on="on"
                  ><v-icon>mdi-truck-delivery</v-icon></v-btn
                ></template
              ><span>Esporta ZIP MOS</span></v-tooltip
            >
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('269')"
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="exportInvoices(item)"
                  v-on="on"
                  ><v-icon>mdi-file-document-outline</v-icon></v-btn
                ></template
              ><span>Esporta Excel Fatture</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('270')"
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="exportCsv(item)"
                  v-on="on"
                  ><v-icon>mdi-file</v-icon></v-btn
                ></template
              ><span>Esporta CSV</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('271')"
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="exportExcel(item)"
                  v-on="on"
                  ><v-icon>mdi-file-excel</v-icon></v-btn
                ></template
              ><span>Esporta Excel</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('272')"
                  x-small
                  text
                  icon
                  color="orange"
                  @click.stop="exportCourierExcel(item)"
                  v-on="on"
                  ><v-icon>mdi-file-excel</v-icon></v-btn
                ></template
              ><span>Esporta Excel Spedizioniere</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('273')"
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteExport(item)"
                  v-on="on"
                  ><v-icon>mdi-delete</v-icon></v-btn
                ></template
              ><span>Elimina esportazione</span></v-tooltip
            >
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <div style="height:20px" />
  </div>
</template>

<script>
import staticData from "../apis/staticData";
import subscriptionsManager from "../apis/subscriptions";
import messageBox from "../components/MessageBox";
import setString from "../components/SetString";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      exports: [],
      shipments: [],
      totalExports: 0,
      filter: null,
      loadingExports: false,

      perPage: staticData.defaultPerPage,
      exportsOptions: {
        sortBy: ["date"],
        sortDesc: [true],
        itemsPerPage: staticData.defaultPerPage
      },
      date_start_modal: false,
      date_end_modal: false,
      date_start: null,
      date_end: null,
      headers: [],

      filters: {
        filterByShipmentId: null,
        filterByPeriod: 14,
        filterByCustomDateEnd: null,
        filterByCustomDateStart: null
      },

      fetchingFilters: false
    };
  },

  components: {
    messageBox,
    setString
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      }
    },

    getPeriodFilters: {
      get: function() {
        return staticData.filtersPeriods();
      }
    }
  },

  mounted() {
    this.headers = [
      {
        text: "ID Export",
        value: "id"
      },
      {
        text: "Descrizione",
        value: "description"
      },
      {
        text: "Data",
        value: "date"
      },
      {
        text: "Conteggio spedizioni",
        value: "total_subscriptions"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];
    this.fetchFilters();
  },

  watch: {
    exportsOptions: {
      handler() {
        clearTimeout(this._productsDebounceTimer);
        this._productsDebounceTimer = setTimeout(() => {
          this.reloadExports();
        }, 300);
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadExports();
        }, 300);
      },
      deep: true
    }
  },

  methods: {
    fetchFilters: function() {
      this.fetchingFilters = true;
      const ctx = {
        sortBy: "start_date",
        sortDesc: true
      };
      subscriptionsManager
        .fetchShipments(ctx)
        .then(res => {
          this.shipments = [
            { id: null, name: this.$t("gbl-all-female") },
            ...res.items
          ];
          this.fetchingFilters = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingFilters = false;
        });
    },

    exportMos: function(item) {
      subscriptionsManager.downloadExportMOS(item.id);
    },

    exportInvoices: function(item) {
      subscriptionsManager.downloadExportInvoices(item.id);
    },

    exportCsv: function(item) {
      subscriptionsManager.downloadExportCSV(item.id);
    },

    exportExcel: function(item) {
      subscriptionsManager.downloadExportExcel(item.id);
    },

    exportCourierExcel: function(item) {
      subscriptionsManager.downloadExportCourierExcel(item.id);
    },

    deleteExport: function(item) {
      this.$refs.msgBox
        .show(
          "Vuoi davvero confermare la cancellazione dell' export ?",
          "Conferma operazione"
        )
        .then(() => {
          subscriptionsManager
            .deleteExport(item.id)
            .then(() => {
              this.reloadExports();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    reloadExports: function() {
      this.loadingExports = true;
      this.exports = [];
      this.totalExports = 0;
      this.fetchExports()
        .then(data => {
          this.exports = data.items;
          this.totalExports = data.totalCount;
          this.loadingExports = false;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchExports() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.exportsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        subscriptionsManager
          .fetchExports(ctx)
          .then(result => {
            this.loading = false;
            resolve(result);
          })
          .catch(err => {
            this.loading = false;
            reject(err);
          });
      });
    },

    setDescription: function(item) {
      this.$refs.setDescription
        .show(
          "Imposta descrizione",
          "Esportazione",
          "Inserisci la descrizione per l'esportazione",
          item.description,
          item,
          false,
          false
        )
        .then(res => {
          let description = res.value;
          subscriptionsManager
            .setExportDescription(item.id, description)
            .then(() => {
              item.description = description;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          // No action , we reject on cancel
        });
    }
  }
};
</script>

<template>
    <v-container justify-center fluid grid-list-xl>
      <v-layout justify-center wrap>
        <messageBox ref="msgBox" />
        <applicationEditor
          @applicationConfigured="handleApplication"
          ref="applicationEditor"
          v-model="applicationEditorDialog"
        ></applicationEditor>
  
        <v-flex md12>
          <v-btn v-on:click="addApp()" color="primary">{{
            $t("appmng-add")
          }}</v-btn>
          <base-material-card
            color="primary"
            icon="mdi-application"
            inline
            class="px-5 py-3"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light card-header">
                {{ $t("appmng") }}
              </div>
            </template>
            <v-text-field
              :value="filter"
              @change="(v) => (filter = v)"
              append-icon="mdi-magnify"
              class="mr-auto pl-1"
              :label="$t('gbl-search')"
              hide-details
              single-line
              style="max-width: 250px;"
            />
  
            <div class="vertical-spacer" />
            <v-data-table
              class="grid-table"
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="applications"
              :options.sync="options"
              :server-items-length="totalApplications"
              :loading="loading"
              :footer-props="dataTableFooterOptions"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="secondary"
                      @click.stop="editApp(item)"
                      v-on="on"
                      ><v-icon>mdi-qrcode-edit</v-icon></v-btn
                    ></template
                  ><span>{{ $t("appmng-modify-app") }}</span></v-tooltip
                >
  
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="error"
                      @click.stop="deleteApp(item)"
                      v-on="on"
                      ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("appmng-remove-app") }}</span></v-tooltip
                >
              </template>
            </v-data-table>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import applicationManager from "../apis/applications";
  import staticData from "../apis/staticData";
  
  import messageBox from "../components/MessageBox.vue";
  import applicationEditor from "../components/ApplicationEditor.vue";
  
  export default {
    data() {
      return {
        dataTableFooterOptions: staticData.dataTableFooterOptions,
        applications: [],
        loading: false,
        totalApplications: 0,
        options: {
          sortBy: ["ID"],
          sortDesc: [false],
          itemsPerPage: 50,
          page: 1,
        },
        headers: [],
        perPage: staticData.defaultPerPage,
        filter: "",
        applicationEditorDialog: false,
      };
    },
  
    components: {
      applicationEditor,
      messageBox,
    },
  
    mounted() {
      this.headers = [
        {
          text: this.$t("appmng-tbl-id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("appmng-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("appmng-tbl-description"),
          align: "left",
          sortable: true,
          value: "description",
        },
        {
          text: this.$t("appmng-tbl-rights"),
          align: "center",
          sortable: true,
          value: "rights_count",
        },
        { text: "", align: "center", sortable: false, value: "actions" },
      ];
    },
  
    watch: {
      options: {
        handler() {
          this.reloadApplications(true);
        },
        deep: true,
      },
  
      filter: {
        handler() {
          this.currentPage = 1;
          this.reloadApplications(false);
        },
      },
    },
  
    methods: {
      reloadApplications: function(showLoader) {
        this.fetchApplications(showLoader)
          .then((data) => {
            this.applications = data.items;
            this.totalApplications = data.totalCount;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      fetchApplications(showLoader) {
        if (showLoader) this.loading = true;
        return new Promise((resolve, reject) => {
          let ctx = Object.assign({}, this.options);
          ctx.currentPage = ctx.page;
          ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
          ctx.filter = this.filter;
          ctx.sortBy = ctx.sortBy[0];
          ctx.sortDesc = ctx.sortDesc[0];
          applicationManager
            .fetchApplications(ctx)
            .then((result) => {
              if (showLoader) this.loading = false;
              resolve(result);
            })
            .catch((err) => {
              if (showLoader) this.loading = false;
              console.log(err);
              reject();
            });
        });
      },
  
      handleApplication(event) {
        this.applicationEditorDialog = false; // Close dialog
  
        if (event.editingApplication) {
          let app = event.app;
          applicationManager
            .updateApplication(app)
            .then(() => {
              this.reloadApplications(false);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (event.app) {
          let app = event.app;
          applicationManager
            .addApplication(app)
            .then(() => {
              this.reloadApplications(false);
            })
            .catch((err) => {
              console.log(err);
            });
        } else this.reloadApplications(false);
      },
  
      deleteApp(item) {
        let appId = item.id;
        this.$refs.msgBox
          .show(
            this.$t("msgbox-confirm-op"),
            this.$t("appmng-msgbox-confirm-delete")
          )
          .then(() => {
            applicationManager
              .removeApplication(appId)
              .then(() => {
                this.reloadApplications(false);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {});
      },
  
      editApp(item) {
        let app = JSON.parse(JSON.stringify(item));
        this.$refs.applicationEditor.resetModal();
        this.$refs.applicationEditor.setModalApp(app, true, item);
        this.applicationEditorDialog = true;
      },
  
      addApp() {
        this.$refs.applicationEditor.resetModal();
        this.$refs.applicationEditor.setModalApp(
          { name: "new-app", description: "new-description" },
          false,
          null
        );
        this.applicationEditorDialog = true;
      },
    },
  };
  </script>
  
import axios from "axios";
import config from "../config";
import store from "../store";

let tickets = {
  openTicket: (key, item_id, ticket) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/openTicket",
        data: {
          backend: store.state.backend,
          item_id: item_id,
          ticket: ticket,
          key: key
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTicket: (key, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/fetchTicket",
        data: {
          backend: store.state.backend,
          id: id,
          key: key
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  closeTicket: (key, ticket) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/closeTicket",
        data: {
          backend: store.state.backend,
          ticket: ticket,
          key: key
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addTicket: (key, ticket) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/addTicket",
        data: {
          backend: store.state.backend,
          ticket: ticket,
          key: key
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeTicket: (key, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/removeTicket",
        data: {
          backend: store.state.backend,
          item_id: id,
          key: key
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default tickets;

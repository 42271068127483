<template>
  <div>
    <messageBox ref="msgBox" />
    <priceListEditor
      ref="priceListEditor"
      @pricelistConfigured="handlePriceList"
      v-model="priceListEditorDialog"
    />

    <priceListsBulkChanges
      @bulkChangesConfirmed="bulkChangesConfirmed"
      v-model="priceListsBulkChangesDialog"
      ref="priceListsBulkChanges"
    />

    <v-card class="ma-4">
      <v-toolbar color="primary">
        <v-icon color="white" large class="mr-2">mdi-format-list-text</v-icon>
        <v-toolbar-title class="white--text">{{
          $t("pricelists-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height:10px;" />
      </div>

      <v-row
        v-if="$store.state.hasRight('161') || $store.state.hasRight('162')"
        class="text-center"
      >
        <v-col v-if="$store.state.hasRight('161')">
          <v-btn
            :disabled="fetchingData"
            color="info"
            @click.stop="addPriceList()"
            >{{ $t("pricelists-add-list") }}</v-btn
          >
        </v-col>

        <v-col v-if="$store.state.hasRight('162')">
          <v-btn
            :disabled="fetchingData"
            color="secondary"
            @click.stop="bulkChanges()"
            >{{ $t("pricelists-bulk-changes") }}</v-btn
          >
        </v-col>
      </v-row>

      <div style="margin:15px">
        <v-row justify="center">
          <v-col>
            <v-text-field
              clearable
              dense
              v-model="filter"
              outlined
              :label="$t('pricelist-filter-pricelist')"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="pricelists"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="fetchingData"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.num_changed_prices="{ item }">
          <span style="color:red;" v-if="item.num_changed_prices">{{
            item.num_changed_prices
          }}</span>
          <span style="color:green;" v-if="!item.num_changed_prices">{{
            item.num_changed_prices
          }}</span>
          <span>
            <v-tooltip v-if="item.num_changed_prices" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="red"
                  @click="acceptVariations(item.id)"
                  v-on="on"
                  ><v-icon>mdi-cash-check</v-icon></v-btn
                ></template
              ><span>{{ $t("pricelist-accept-variantions") }}</span></v-tooltip
            >
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <div style="min-width:120px">
            <v-tooltip v-if="$store.state.hasRight('198')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="green"
                  @click="downloadPriceList(item, false)"
                  v-on="on"
                  ><v-icon>mdi-arrow-down</v-icon></v-btn
                ></template
              ><span>{{ $t("pricelist-action-download") }}</span></v-tooltip
            >

            <v-tooltip v-if="$store.state.hasRight('198')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="orange darken-2"
                  @click="downloadPriceList(item, true)"
                  v-on="on"
                  ><v-icon>mdi-arrow-down</v-icon></v-btn
                ></template
              ><span>{{
                $t("pricelist-action-download-complete")
              }}</span></v-tooltip
            >

            <v-tooltip v-if="$store.state.hasRight('162')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="gray"
                  @click.stop="editPriceList(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>{{ $t("pricelist-action-edit") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="
                $store.state.hasRight('163') &&
                  item.num_masters == 0 &&
                  item.num_customers == 0
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deletePriceList(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("pricelist-action-delete") }}</span></v-tooltip
            >
            <v-tooltip
              v-if="
                !$store.state.hasRight('163') ||
                  item.num_masters > 0 ||
                  item.num_customers > 0
              "
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="true"
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deletePriceList(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("pricelist-action-delete") }}</span></v-tooltip
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import priceListsManager from "../apis/pricelists";
import staticData from "../apis/static";
import config from "../config";
import messageBox from "../components/MessageBox";
import priceListEditor from "../components/PriceListEditor";
import priceListsBulkChanges from "../components/PriceListsBulkChanges";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      pricelists: [],
      totalItems: 0,
      perPage: staticData.defaultPerPage,
      filter: null,
      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        priceList: null
      },

      options: {
        sortBy: ["ID"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },

      headers: [],

      fetchingData: false,
      priceListEditorDialog: false,
      priceListsBulkChangesDialog: false
    };
  },

  components: {
    messageBox,
    priceListEditor,
    priceListsBulkChanges
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("pricelist-tbl-name"),
        align: "center",
        sortable: true,
        value: "name"
      },
      {
        text: this.$t("pricelist-tbl-numproducts"),
        align: "center",
        sortable: true,
        value: "num_products"
      },

      {
        text: this.$t("pricelist-tbl-changedproducts"),
        align: "center",
        sortable: true,
        value: "num_changed_prices"
      },
      {
        text: this.$t("pricelist-tbl-numcategories"),
        align: "center",
        sortable: true,
        value: "num_categories"
      },
      {
        text: this.$t("pricelist-tbl-masterlist"),
        align: "center",
        sortable: true,
        value: "master_list"
      },
      {
        text: this.$t("pricelist-tbl-assigned-customers"),
        align: "center",
        sortable: true,
        value: "num_customers"
      },

      { text: "", align: "right", sortable: false, value: "actions" }
    ];
  },

  watch: {
    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchPriceLists();
        }, config.searchInputsFastTimeout);
      },
      deep: true
    },

    filters: {
      handler() {
        this.pricelists = [];
        this.totalItems = 0;

        clearTimeout(this._debounceTimer);
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true]
        };
      },

      deep: true
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true]
        };
      }
    }
  },

  methods: {
    acceptVariations(id) {
      this.$refs.msgBox
        .show(
          this.$t("pricelist-accept-variations-confirm"),
          this.$t("pricelist-accept-variations-title")
        )
        .then(() => {
          priceListsManager
            .acceptVariations(id)
            .then(() => {
              this.fetchPriceLists();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    downloadPriceList(item, complete) {
      priceListsManager
        .downloadPriceList(item.id, this.$store.state.user.locale, complete)
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    },

    bulkChangesConfirmed(state) {
      this.priceListsBulkChangesDialog = false;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "pricelists-bulk-processing"
      );
      priceListsManager
        .bulkChanges(state)
        .then(() => {
          this.fetchPriceLists();
          this.$store.state.global_overlay = false;
        })
        .catch(err => {
          console.log(err);
          this.$store.state.global_overlay = false;
        });
    },

    handlePriceList(event) {
      if (event.editingPriceList) {
        priceListsManager
          .updatePriceList(event.pricelist)
          .then(() => {
            this.fetchPriceLists();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        priceListsManager
          .addPriceList(event.pricelist)
          .then(() => {
            this.fetchPriceLists();
          })
          .catch(err => {
            console.log(err);
          });
      }
      this.priceListEditorDialog = false;
    },

    addPriceList() {
      this.$refs.priceListEditor.setItem(false);
      this.priceListEditorDialog = true;
    },

    bulkChanges() {
      this.$refs.priceListsBulkChanges.resetModal();
      this.priceListsBulkChangesDialog = true;
    },

    editPriceList(item) {
      this.$refs.priceListEditor.setItem(true, item);
      this.priceListEditorDialog = true;
    },

    deletePriceList(item) {
      this.$refs.msgBox
        .show(
          this.$t("pricelist-delete-confirm"),
          this.$t("pricelist-delete-title")
        )
        .then(() => {
          priceListsManager
            .removePriceList(item.id)
            .then(() => {
              this.fetchPriceLists();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchPriceLists: function() {
      this.fetchingData = true;
      this.pricelists = [];
      this.totalItems = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.filters = this.filters;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      priceListsManager
        .fetchPriceLists(ctx)
        .then(results => {
          this.fetchingData = false;
          this.pricelists = results.items;
          this.totalItems = results.totalCount;
        })
        .catch(err => {
          this.fetchingData = false;
          console.log(err);
        });
    }
  }
};
</script>

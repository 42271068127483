<template>
  <div>
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <v-progress-circular
            v-if="loadingStatistics"
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="light-blue"
          ></v-progress-circular>

          <base-material-card
            v-if="$store.state.hasRight('231')"
            class="pa-0"
            color="primary"
          >
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0">
                  <v-col cols="1" class="pa-0">
                    <img style="width:22px" src="@/assets/us.png" /> </v-col
                ></v-col>
                <v-col
                  align="center"
                  cols="11"
                  class="pa-0 card-title-text"
                  style="font-size: 20px"
                  >Statistiche Wine Club Internazionale</v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <v-row class="text-center">
                <v-col>
                  <v-row>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="success"
                        icon="mdi-car-turbocharger"
                        title="Sottoscrizioni attive"
                        :value="intSubscriptionsStatus[0].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="primary"
                        icon="mdi-delete-circle-outline"
                        title="Sottoscrizioni cancellate"
                        :value="intSubscriptionsStatus[1].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="secondary"
                        icon="mdi-timelapse"
                        title="Sottoscrizioni sospese"
                        :value="intSubscriptionsStatus[3].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="purple"
                        icon="mdi-close-circle-multiple-outline"
                        title="Sottoscrizioni estinte"
                        :value="intSubscriptionsStatus[4].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" lg="6">
                  <base-material-chart-card
                    :data="int_subscriptionsChart.data"
                    :options="int_subscriptionsChart.options"
                    color="primary"
                    type="Line"
                  >
                    <h4 class="card-title font-weight-light mt-2 ml-2">
                      {{ "Sottoscrizioni" }}
                    </h4>
                  </base-material-chart-card>
                </v-col>

                <v-col cols="12" sm="6" lg="6">
                  <base-material-chart-card
                    :data="int_ordersChart.data"
                    :options="int_ordersChart.options"
                    color="secondary"
                    type="Line"
                  >
                    <h4 class="card-title font-weight-light mt-2 ml-2">
                      {{ "Ordini" }}
                    </h4>
                  </base-material-chart-card>
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>

          <base-material-card
            v-if="$store.state.hasRight('232')"
            class="pa-0"
            color="primary"
          >
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><img style="width:22px;" src="@/assets/it.png" />
                </v-col>
                <v-col
                  align="center"
                  cols="11"
                  class="pa-0 card-title-text"
                  style="font-size: 20px"
                  >Statistiche Wine Club Italiano</v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <v-row class="text-center">
                <v-col>
                  <v-row>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="success"
                        icon="mdi-car-turbocharger"
                        title="Sottoscrizioni attive"
                        :value="itSubscriptionsStatus[0].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="primary"
                        icon="mdi-delete-circle-outline"
                        title="Sottoscrizioni cancellate"
                        :value="itSubscriptionsStatus[1].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="secondary"
                        icon="mdi-timelapse"
                        title="Sottoscrizioni sospese"
                        :value="itSubscriptionsStatus[3].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" :lg="computeStatsCols">
                      <base-material-stats-card
                        color="purple"
                        icon="mdi-close-circle-multiple-outline"
                        title="Sottoscrizioni estinte"
                        :value="itSubscriptionsStatus[4].toString()"
                        sub-icon="mdi-calendar"
                        :sub-text="filterByPeriodText"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>

          <v-row>
            <v-col cols="12" sm="6" lg="6">
              <base-material-chart-card
                :data="it_subscriptionsChart.data"
                :options="it_subscriptionsChart.options"
                color="primary"
                type="Line"
              >
                <h4 class="card-title font-weight-light mt-2 ml-2">
                  {{ "Sottoscrizioni" }}
                </h4>
              </base-material-chart-card>
            </v-col>

            <v-col cols="12" sm="6" lg="6">
              <base-material-chart-card
                :data="it_ordersChart.data"
                :options="it_ordersChart.options"
                color="secondary"
                type="Line"
              >
                <h4 class="card-title font-weight-light mt-2 ml-2">
                  {{ "Ordini" }}
                </h4>
              </base-material-chart-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <base-material-card
                v-if="$store.state.hasRight('234')"
                class="pa-0"
                color="secondary"
              >
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col
                      align="center"
                      class="pa-0 card-title-text"
                      style="font-size: 25px"
                      >Classifica WC INT</v-col
                    >
                  </v-row>
                </template>
                <v-card-text>
                  <v-row dense>
                    <v-col>
                      <v-select
                        v-model="int_shipment_id"
                        outlined
                        item-text="name"
                        item-value="id"
                        :items="int_shipments"
                        label="Spedizione"
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col class="body-1">
                      Totali : {{ top_boxes_int_total }}
                    </v-col>
                    <v-col class="body-1">
                      Totali Box da 12 : {{ top_boxes_12_int_total }}
                    </v-col>
                  </v-row>

                  <v-data-table
                    v-if="top_boxes_int"
                    :headers="headers"
                    hide-default-header
                    hide-default-footer
                    :items="top_boxes_int"
                    class="elevation-1"
                  ></v-data-table>
                </v-card-text>
              </base-material-card>
            </v-col>
            <v-col cols="6">
              <base-material-card
                v-if="$store.state.hasRight('233')"
                class="pa-0"
                color="secondary"
              >
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col
                      align="center"
                      class="pa-0 card-title-text"
                      style="font-size: 25px"
                      >Classifica WC ITA</v-col
                    >
                  </v-row>
                </template>
                <v-card-text>
                  <v-row dense>
                    <v-col>
                      <v-select
                        v-model="it_shipment_id"
                        outlined
                        item-text="name"
                        item-value="id"
                        :items="it_shipments"
                        label="Spedizione"
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="body-1">
                      Totali : {{ top_boxes_it_total }}
                    </v-col>
                    <v-col class="body-1"> </v-col>
                  </v-row>

                  <v-data-table
                    v-if="top_boxes_it"
                    :headers="headers"
                    hide-default-header
                    hide-default-footer
                    :items="top_boxes_it"
                    class="elevation-1"
                  ></v-data-table>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>
          <div style="height:50px" />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import subscriptionsManager from "../apis/subscriptions";

export default {
  data() {
    return {
      int_ordersChart: {
        data: {
          labels: [],
          series: [[]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },

      int_subscriptionsChart: {
        data: {
          labels: [],
          series: [[]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },
      it_ordersChart: {
        data: {
          labels: [],
          series: [[]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },

      it_subscriptionsChart: {
        data: {
          labels: [],
          series: [[]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },

      loadingStatistics: false,
      intSubscriptionsStatus: [0, 0, 0, 0, 0, 0],
      itSubscriptionsStatus: [0, 0, 0, 0, 0, 0],
      int_shipments: [],
      it_shipments: [],
      int_shipment_id: null,
      it_shipment_id: null,
      top_boxes_it: null,
      top_boxes_int: null,
      top_boxes_it_total: 0,
      top_boxes_int_total: 0,
      top_boxes_12_int_total: 0,
      headers: [
        { text: "Box", value: "item_name" },
        { text: "Numero", value: "qty" }
      ]
    };
  },

  watch: {
    int_shipment_id: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadStatistics();
        }, 300);
      }
    },
    it_shipment_id: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadStatistics();
        }, 300);
      }
    }
  },

  mounted() {
    this.loadingStatistics = true;
    subscriptionsManager
      .fetchShipmentsFilters("int")
      .then(result => {
        this.int_shipments = [
          { id: null, name: "Tutte le spedizioni" },
          ...result.items
        ];
        subscriptionsManager
          .fetchShipmentsFilters("it")
          .then(result => {
            this.it_shipments = [
              { id: null, name: "Tutte le spedizioni" },
              ...result.items
            ];
            this.loadingStatistics = false;
            this.reloadStatistics();
          })
          .catch(err => {
            console.log(err);
            this.loadingStatistics = false;
          });
      })
      .catch(err => {
        console.log(err);
        this.loadingStatistics = false;
      });
  },

  computed: {
    computeStatsCols() {
      return "3";
    },

    filterByPeriodText() {
      return "Periodo attuale";
    }
  },

  components: {},

  methods: {
    reloadStatistics() {
      this.intSubscriptionsStatus = [0, 0, 0, 0, 0, 0];
      this.itSubscriptionsStatus = [0, 0, 0, 0, 0, 0];
      this.top_boxes_it = null;
      this.top_boxes_int = null;

      this.loadingStatistics = true;
      subscriptionsManager
        .fetchStatistics(this.int_shipment_id, this.it_shipment_id)
        .then(result => {
          this.intSubscriptionsStatus = [
            result.int_statistics[0].active_count -
              result.int_statistics[0].cancelled_count -
              result.int_statistics[0].pending_cancel_count -
              result.int_statistics[0].onhold_count -
              result.int_statistics[0].expired_count,
            result.int_statistics[0].cancelled_count,
            result.int_statistics[0].pending_cancel_count,
            result.int_statistics[0].onhold_count,
            result.int_statistics[0].expired_count,
            result.int_statistics[0].active_count +
              result.int_statistics[0].cancelled_count +
              result.int_statistics[0].pending_cancel_count +
              result.int_statistics[0].onhold_count +
              result.int_statistics[0].expired_count
          ];
          this.itSubscriptionsStatus = [
            result.it_statistics[0].active_count -
              result.it_statistics[0].cancelled_count -
              result.it_statistics[0].pending_cancel_count -
              result.it_statistics[0].onhold_count -
              result.it_statistics[0].expired_count,
            result.it_statistics[0].cancelled_count,
            result.it_statistics[0].pending_cancel_count,
            result.it_statistics[0].onhold_count,
            result.it_statistics[0].expired_count,
            result.it_statistics[0].active_count +
              result.it_statistics[0].cancelled_count +
              result.it_statistics[0].pending_cancel_count +
              result.it_statistics[0].onhold_count +
              result.it_statistics[0].expired_count
          ];
          this.top_boxes_it = result.ladder_it;
          let sum = 0;
          for (let x in this.top_boxes_it) {
            sum += this.top_boxes_it[x].qty;
          }
          this.top_boxes_it_total = sum;

          this.top_boxes_int = result.ladder_int;
          sum = 0;
          for (let x in this.top_boxes_int) {
            sum += this.top_boxes_int[x].qty;
          }
          this.top_boxes_int_total = sum;
          this.top_boxes_12_int_total = result.top_boxes_12_int_total;

          this.int_ordersChart.data.labels = result.int_orders_labels;
          this.int_ordersChart.data.series = [result.int_orders_values];
          this.int_ordersChart.options.high = Math.max.apply(
            Math,
            result.int_orders_values
          );

          this.int_subscriptionsChart.data.labels =
            result.int_subscriptions_labels;
          this.int_subscriptionsChart.data.series = [
            result.int_subscriptions_values
          ];
          this.int_subscriptionsChart.options.high = Math.max.apply(
            Math,
            result.int_subscriptions_values
          );

          this.it_ordersChart.data.labels = result.it_orders_labels;
          this.it_ordersChart.data.series = [result.it_orders_values];
          this.it_ordersChart.options.high = Math.max.apply(
            Math,
            result.it_orders_values
          );

          this.it_subscriptionsChart.data.labels =
            result.it_subscriptions_labels;
          this.it_subscriptionsChart.data.series = [
            result.it_subscriptions_values
          ];
          this.it_subscriptionsChart.options.high = Math.max.apply(
            Math,
            result.it_subscriptions_values
          );

          this.loadingStatistics = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingStatistics = false;
        });
    }
  }
};
</script>

<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("groupeditor-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-row v-if="fetchingData"
              ><v-col>
                <v-progress-circular
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular> </v-col
            ></v-row>
            <v-form
              class="mt-5"
              v-if="!fetchingData"
              ref="groupeditor-editor-form"
            >
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <ValidationProvider
                  :immediate="editingGroup"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="group.name"
                    :label="$t('groupeditor-hint-name')"
                    prepend-icon="mdi-account"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>

                <div style="height:10px" />

                <v-row>
                  <v-col>
                    <v-card>
                      <v-toolbar dense color="primary accent-4" dark>
                        <v-toolbar-title class="white--text ma-0 text-center">{{
                          $t("groupeditor-rights")
                        }}</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="pa-0">
                        <v-row class="pa-0">
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-group
                                v-for="right in rights"
                                :key="right.item.id"
                                v-model="right.item.active"
                                no-action
                              >
                                <v-icon slot="prependIcon" color="success"
                                  >mdi-application</v-icon
                                >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="text-left"
                                      v-text="right.item.app_description"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <v-list-item
                                  v-for="child in right.childs"
                                  :key="child.id"
                                >
                                  <v-list-item-title
                                    class="text-left"
                                    v-text="child.name"
                                  ></v-list-item-title>
                                  <v-list-item-icon>
                                    <v-btn
                                      @click="removeRight(child)"
                                      icon
                                      dark
                                      color="red"
                                    >
                                      <v-icon>mdi-delete-circle-outline</v-icon>
                                    </v-btn>
                                  </v-list-item-icon>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                          </v-col>
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-group
                                v-for="right in availableRights"
                                :key="right.item.id"
                                v-model="right.item.active"
                                no-action
                              >
                                <v-icon slot="prependIcon" color="primary"
                                  >mdi-application</v-icon
                                >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="text-left"
                                      v-text="right.item.app_description"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>

                                <v-list-item
                                  v-for="child in right.childs"
                                  :key="child.id"
                                >
                                  <v-list-item-title
                                    class="text-left"
                                    v-text="child.name"
                                  ></v-list-item-title>
                                  <v-btn
                                    @click="addRight(child)"
                                    icon
                                    dark
                                    color="green"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

<v-row>
<v-col>
                <v-btn
                  width="120"
                  :disabled="invalid || !validated"
                  v-on:click="confirm()"
                  color="orange"
                  >{{ $t("gbl-ok") }}</v-btn
                >
                <v-btn width="120" v-on:click="cancel()" color="primary">{{
                  $t("gbl-cancel")
                }}</v-btn>
                </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import applicationManager from "../apis/applications";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      group: {},
      applications: {},
      applications_rights: {},
      availableRights: {},
      rights: {},
      editingGroup: false,
      fetchingData: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {
    this.reloadAppicationsAndPermissions();
  },

  watch: {},

  methods: {
    removeRight: function(item) {
      if (!this.group.rights) this.group.rights = "";
      let items = this.group.rights.split(",").filter(i => i);
      items = items.filter((x) => x != item.id);
      items.sort((a, b) => a - b);
      this.group.rights = items.join(",");
      this.calculateRights();
    },

    addRight: function(item) {
      if (!this.group.rights) this.group.rights = "";
      let items = this.group.rights.split(",").filter(i => i);
      items.push(item.id);
      items.sort((a, b) => a - b);
      this.group.rights = items.join(",");
      this.calculateRights();
    },

    reloadAppicationsAndPermissions: function() {
      this.applications = {};
      this.rights = {};
      this.fetchApplicationsAndPermissions()
        .then((data) => {
          for (let index in data.items) {
            let app = data.items[index];
            if (!this.applications[app.name]) {
              this.applications[app.name] = {
                description: app.description,
                rights: [],
              };
            }

            this.applications[app.name].rights.push({
              id: app.right_id,
              name: app.right_name,
            });
            this.applications_rights[app.right_id] = {
              id: app.right_id,
              name: app.right_name,
              app_name: app.name,
              app_description: app.description,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchApplicationsAndPermissions() {
      this.fetchingData = true;
      return new Promise((resolve, reject) => {
        applicationManager
          .fetchApplicationsAndPermissions()
          .then((result) => {
            this.fetchingData = false;
            resolve(result);
          })
          .catch((err) => {
            this.fetchingData = false;
            console.log(err);
            reject();
          });
      });
    },

    confirm: function() {
      this.handleSubmit();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    checkFormValidity(callback) {
      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity((res) => {
        if (!res) return false;
        this.$refs.obs.reset();
        this.$emit("groupConfigured", {
          editingGroup: this.editingGroup,
          group: this.group
        });
      });
    },

    resetModal() {},

    buildAvailableRights() {
      this.availableRights = {};
      for (let right in this.applications_rights) {
        let app_right_obj = this.applications_rights[right];
        let right_obj = this.rights[app_right_obj.app_name];
        if (
          !right_obj ||
          !right_obj.childs.some((item) => {
            return item.id == app_right_obj.id;
          })
        ) {
          if (!this.availableRights[app_right_obj.app_name])
            this.availableRights[app_right_obj.app_name] = {
              item: app_right_obj,
              childs: [],
            };
          this.availableRights[app_right_obj.app_name].childs.push(
            app_right_obj
          );
        }
      }
    },

    calculateRights() {
      let group_rights = [];
      this.rights = {};
      if (this.group.rights) group_rights = this.group.rights.split(",").filter(i => i);
      for (let index in group_rights) {
        let right = group_rights[index];
        if (this.applications_rights[right]) {
          let right_obj = this.applications_rights[right];
          if (!this.rights[right_obj.app_name])
            this.rights[right_obj.app_name] = { item: right_obj, childs: [] };
          this.rights[right_obj.app_name].childs.push(right_obj);
        }
      }
      this.buildAvailableRights();
    },

    setModalGroup(group, editing) {
      this.group = Object.assign({}, group);
      this.calculateRights();

      this.editingGroup = editing;
      if (!this.editingGroup) this.resetModal();

      if (editing) {
        requestAnimationFrame(() => {
          if (this.$refs.obs) {
            this.$refs.obs.validate();
          }
        });
      } else {
        requestAnimationFrame(() => {
          if (this.$refs.obs) this.$refs.obs.reset();
        });
      }

      /* eslint-enable no-unused-vars */
    },
  },
};
</script>

import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let pricelists = new Object({
  fetchProductPrice: (customer_id, item_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetchProductPrice",
        data: { customer_id: customer_id, item_id: item_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchMasterLists: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetchMasterLists",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.lists);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPriceListsNames: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetchPriceListsNames",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.lists);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPriceListsFilter: (filter, id_to_filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/filter",
        data: { ctx: { filter: filter, id_to_filter: id_to_filter } },
        method: "POST",
      })
        .then((resp) => {
          resolve({ pricelists: resp.data.responseData.pricelists });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchProducts: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetchProducts",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData.products);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCategories: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetchCategories",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData.categories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePriceList: (pricelist) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/update",
        data: { pricelist: pricelist },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPriceList: (pricelist) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/add",
        data: { pricelist: pricelist },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removePriceList: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPriceLists: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.pricelists,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTypes: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/types",
        data: {},
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData.types);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  bulkChanges: (state) => {
    state.selected_pricelists = state.selected_pricelists.map((x) => {
      return x.id;
    });
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/bulk",
        data: { state: state },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  acceptVariations: (id) => {
  
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/acceptVariations",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadPriceList: (id, locale, complete) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/pricelist/pricelist-" +
          encodeURIComponent(id) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&id=" +
          encodeURIComponent(id) +
          "&complete=" +
          (complete ? "1" : "0") +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
});

export default pricelists;

<template>
  <ManualOrders location="it"> </ManualOrders>
</template>

<script>
import ManualOrders from "../components/ManualOrders";

export default {
  components: {
    ManualOrders
  }
};
</script>

<template>
  <v-dialog overlay-opacity="0.6" max-width="800" :value="openDialog">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0 " color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col
                  align="center"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ title }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <ValidationObserver
              v-if="required"
              ref="obs"
              v-slot="{ invalid, validated }"
            >
              <v-card-text>
                <span v-if="text">{{ text }}</span>
                <ValidationProvider
                  rules="required|decimal:2"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="value"
                    :label="label"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>

                <v-text-field
                  v-if="!required"
                  dense
                  outlined
                  v-model="value"
                  class="ml-auto"
                  :label="label"
                />
              </v-card-text>
              <v-btn
                width="120"
                :disabled="invalid"
                @click="confirm()"
                :color="okvariant"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn width="120" @click="dismiss()" :color="cancelvariant">{{
                $t("gbl-cancel")
              }}</v-btn>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "",
      label: "",
      value: 0,
      text: null,
      initial_value: 0,
      string_state: true,
      context: null,
      required: true,
      mustChange: false,
      okvariant: "primary",
      cancelvariant: "secondary",
      openDialog: false,
      dialogResolve: null,
      dialogReject: null
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider
  },

  methods: {
    show(
      title,
      label,
      text,
      initial_value,
      context = null,
      required = true,
      mustChange = true,
      okvariant = "primary",
      cancelvariant = "secondary"
    ) {
      return new Promise((resolve, reject) => {
        this.value = initial_value;
        this.title = title;
        this.label = label;
        this.text = text;
        this.initial_value = initial_value;
        this.value = initial_value;
        this.okvariant = okvariant;
        this.cancelvariant = cancelvariant;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.required = required;
        this.context = context;
        this.mustChange = mustChange;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      if (!this.required || this.value.toString().length > 0) {
        if (this.value != this.initial_value || !this.mustChange) {
          this.openDialog = false;
          this.dialogResolve({ context: this.context, value: this.value });
        }
      }
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>

<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-dialog
        overlay-opacity="0.6"
        max-width="600"
        persistent
        :value="cropperDialog"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0 ma-0">
                    <v-col cols="1" class="pa-2 ma-0"
                      ><v-icon large>mdi-account</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="11"
                      class="pa-0 ma-0 card-title-text display-2"
                      >{{ $t("profile-avatar-position") }}</v-col
                    >
                  </v-row>
                </template>
                <div style="height:20px" />
                <div v-show="croppieVisible">
                  <v-btn small text icon @click="rotate(-90)"
                    ><v-icon>mdi-rotate-right</v-icon></v-btn
                  >
                  <v-btn small text icon @click="rotate(90)"
                    ><v-icon>mdi-rotate-left</v-icon></v-btn
                  >
                  <vue-croppie
                    ref="croppieRef"
                    :enableOrientation="true"
                    :enable-resize="true"
                    :boundary="{ width: 200, height: 200 }"
                    :viewport="{ width: 200, height: 200, type: 'square' }"
                  />
                </div>
                <div v-show="!croppieVisible">
                  <v-progress-circular
                    :enableExif="true"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="primary"
                  ></v-progress-circular>
                  <div style="height:20px" />
                </div>
                <!-- the result -->
                <img v-bind:src="croppedImage" />
                <v-btn
                  v-show="croppieVisible && !cropping"
                  @click="crop()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >
                <v-btn
                  v-show="croppieVisible && !cropping"
                  @click="closeCrop()"
                  color="primary"
                  >{{ $t("gbl-cancel") }}</v-btn
                >
                <v-progress-circular
                  v-show="cropping"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>

      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-package-variant-closed</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("product-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <div v-if="fetching">
              <div style="height:10px" />
              <v-progress-circular
                :enableExif="true"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>
              <div style="height:10px" />
            </div>

            <ValidationObserver
              v-if="!fetching"
              ref="obs"
              v-slot="{ invalid, validated }"
            >
              <div style="height:30px" />
              <v-row align="center" justify="center">
                <v-img
                  v-if="!croppedImage"
                  class="center"
                  max-width="200"
                  max-height="200"
                  min-width="200"
                  min-height="200"
                  :src="require('../assets/product.png')"
                />
                <v-img
                  v-if="croppedImage"
                  class="center"
                  max-width="200"
                  max-height="200"
                  min-width="200"
                  min-height="200"
                  :src="croppedImage"
                />
              </v-row>
              <div style="height:30px" />

              <image-input v-if="product.id" v-model="imageLoader">
                <div slot="activator">
                  <v-btn text icon color="primary"
                    ><v-icon color="gray" size="24">mdi-camera</v-icon></v-btn
                  >
                  <p>{{ $t("strmng-load-image") }}</p>
                </div>
              </image-input>

              <v-row dense>
                <v-col cols="2">
                  <v-text-field
                    :value="getProductCode()"
                    :label="$t('product-edit-hint-code')"
                    required
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.name"
                      :label="$t('product-edit-hint-name-it')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.name_en"
                      :label="$t('product-edit-hint-name-en')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="product.description"
                    :label="$t('product-edit-hint-description')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    :label="$t('product-edit-hint-supplier')"
                    v-model="product.supplier_id"
                    :items="suppliers"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      :label="$t('product-edit-hint-category')"
                      v-model="product.category_id"
                      :items="categories"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :error-messages="errors"
                      :success="valid"
                    >
                    </v-select
                  ></ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required"
                    v-slot="{ errors, valid }"
                    ><v-select
                      :label="$t('product-edit-hint-macrocategory')"
                      v-model="product.macrocategory_id"
                      :items="macroCategories"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :error-messages="errors"
                      :success="valid"
                    >
                    </v-select
                  ></ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                    :label="$t('product-edit-hint-weight-type')"
                    v-model="product.weight_type"
                    :items="weightUnits"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                  >
                    <template slot="selection" slot-scope="data">
                      {{ $t(data.item.name) }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ $t(data.item.name) }}
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.net_weight"
                      :label="$t('product-edit-hint-net-weight')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  ><v-text-field
                    v-model="product.gross_weight"
                    :label="$t('product-edit-hint-gross-weight')"
                    required
                    outlined
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="product.vol"
                    :label="$t('product-edit-hint-vol')"
                    required
                    outlined
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.shelf_life"
                      :label="$t('product-edit-hint-shelf-life')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.pieces_per_carton"
                      :label="$t('product-edit-hint-pieces-per-carton')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  ><ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.carton_per_pallet"
                      :label="$t('product-edit-hint-carton-per-pallet')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.carton_height"
                      :label="$t('product-edit-hint-carton-height')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.carton_width"
                      :label="$t('product-edit-hint-carton-width')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.carton_length"
                      :label="$t('product-edit-hint-carton-length')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider> </v-col
              ></v-row>

              <v-row dense>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.price"
                      :label="$t('product-edit-hint-price')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required|min_value:0"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.vat_percent"
                      :label="$t('product-edit-hint-vat')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      type="number"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="true"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      :label="$t('product-edit-hint-unit-type')"
                      v-model="product.measure_unit"
                      :items="measureUnits"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                    >
                      <template slot="selection" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="product.notes"
                    :label="$t('product-edit-hint-notes')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-btn
                    width="120"
                    :disabled="invalid || !validated"
                    v-on:click="confirm()"
                    color="primary"
                    >{{ $t("gbl-ok") }}</v-btn
                  >
                  <v-btn width="120" v-on:click="cancel()" color="secondary">{{
                    $t("gbl-cancel")
                  }}</v-btn>
                </v-col>
              </v-row>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import productsManager from "../apis/products";
import ImageUpload from "../components/ImageUpload.vue";
import config from "../config";
import staticData from "../apis/static";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      product: {
        measure_unit: 1,
        conversion_factor: 1,
        vat_percent: 4.0
      },
      staticData: staticData,
      editingProduct: false,
      categories: [],
      suppliers: [],
      macroCategories: [],
      cropperDialog: false,
      croppedImage: null,
      croppieVisible: false,
      cropping: false,
      imageLoader: null,
      fetching: false
    };
  },

  props: ["value", "measureUnits", "weightUnits"],

  components: {
    ValidationObserver,
    ValidationProvider,
    ImageInput: ImageUpload
  },

  watch: {
    imageLoader: {
      handler: function() {
        if (this.imageLoader) {
          this.saved = false;
          this.cropperDialog = true;
          this.croppieVisible = false;
          this.croppedAvatar = null;
          this.croppedImage = null;
          let that = this;
          setTimeout(function() {
            that.croppieVisible = true;
            that.$refs.croppieRef.bind({
              url: that.imageLoader.imageURL
            });
          }, 400);
        } else {
          this.croppieVisible = false;
        }
      },
      deep: true
    }
  },

  methods: {
    getProductCode() {
      if (this.product && this.product.id) {
        let padding = this.product.id.toString();
        while (padding.length < config.productIdPadding)
          padding = "0" + padding;
        return padding;
      } else {
        return "";
      }
    },

    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },

    crop() {
      let options = {
        format: "png",
        size: { width: 200, height: 200 }
      };
      this.cropping = true;
      this.$refs.croppieRef.result(options, output => {
        productsManager
          .updateProductImage(
            this.product.id,
            output.replace("data:image/png;base64,", "")
          )
          .then(() => {
            this.croppedImage = output;
            this.cropperDialog = false;
            this.croppieVisible = true;
            this.cropping = false;
            this.product.image = true;
          })
          .catch(err => {
            console.log(err);
            this.cropping = false;
            this.cropperDialog = false;
            this.croppieVisible = true;
          });
      });
    },

    closeCrop() {
      this.cropperDialog = false;
      this.croppieVisible = true;
      this.cropping = false;
      //this.product.image = false;
    },

    confirm: function() {
      this.handleSubmit();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    closeDialog: function() {
      this.cancel();
    },

    handleSubmit() {
      this.$emit("productConfigured", {
        editingProduct: this.editingProduct,
        product: this.product
      });
      this.$nextTick(() => {
        this.$emit("input");
      });
    },

    setModalProduct(product, editing) {
      this.product = Object.assign({}, product);
      this.editingProduct = editing;
      this.cropperDialog = false;
      this.croppedImage = null;
      this.croppieVisible = false;
      this.cropping = false;
      this.imageLoader = null;
      this.fetching = true;

      productsManager
        .fetchCategories({ ctx: { sortBy: "description" } })
        .then(results => {
          this.categories = [...results.items];

          productsManager
            .fetchMacroCategories({ ctx: { sortBy: "description" } })
            .then(results => {
              this.macroCategories = [...results.items];
              productsManager
                .fetchSuppliers({ ctx: { sortBy: "description" } })
                .then(results => {
                  this.suppliers = [
                    { id: null, name: this.$t("gbl-none-male") },
                    ...results.items.map(s => {
                      return {
                        id: s.id,
                        name: s.first_name
                          ? s.first_name + " " + s.last_name
                          : s.business_name
                      };
                    })
                  ];
                  if (this.product.image) {
                    productsManager
                      .fetchProductImage(this.product.id)
                      .then(results => {
                        if (results.image)
                          this.croppedImage =
                            "data:image/png;base64," + results.image;
                        this.fetching = false;
                      });
                    // Fetch current image
                  } else {
                    this.fetching = false;
                  }
                });
            });
        })
        .catch(err => {
          console.log(err);
          this.fetching = false;
        });
    }
  }
};
</script>

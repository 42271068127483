<template>
<v-container fill-height fluid grid-list-xl> 
    <v-layout justify-center wrap>
       <v-flex md12>
        <base-material-card color="primary" icon="mdi-file" inline>
          <template v-slot:after-heading>
          <div class="display-1 font-weight-light card-header">{{$t('filemanager-title')}}</div>
        </template>
          <FilesView :selectionMode="false" :path="fileViewPath"></FilesView>
        </base-material-card>
       </v-flex>
    </v-layout>
</v-container>
</template>

<script>

import FilesView from '../components/FilesView.vue'

export default {
    data() {
      return {
        fileViewPath : this.$t('filemanager-root-path')
      }
    },
    
    components : {
      FilesView
    }

  }
</script>
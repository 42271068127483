import axios from 'axios'
import config from '../config'
import localStorageService from '../services/localStorageService'
const path = require('path');
//const mime = require('mime');

let filemanager = {
    fetchFiles : (path) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/filemanager/fetch", data: {path : path }, method: 'POST' })
            .then(resp => {
                const sortedFolders = resp.data.responseData.folders.sort((a, b) => {
                    
                    return a.Prefix.toLowerCase().localeCompare(b.Prefix.toLowerCase());
                });

                const sortedFiles = resp.data.responseData.items.sort((a, b) => {
                    return a.Key.toLowerCase().localeCompare(b.Key.toLowerCase());
                });

                resolve({ files : sortedFiles,folders : sortedFolders });
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    removeFile : (path) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/filemanager/remove", data: {path : path }, method: 'POST' })
            .then(resp => {
                resolve(resp.data.responseData);
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    createFolder : (path) => {
        return new Promise((resolve, reject) => {
            axios({url: config.apiEndPoint+"/filemanager/createFolder", data: {path : path }, method: 'POST' })
            .then(resp => {
                resolve(resp.data.responseData);
            })
            .catch(err => {
                reject(err);
            })
        });
    },

    uploadFile : (formData, progressCallback) => {
        return new Promise((resolve, reject) => {
            axios.post( config.apiEndPoint+'/filemanager/upload',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressCallback.bind(this)
            }).then((resp) => {
                resolve(resp.data.responseData);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    downloadFile : (filePath,/*name*/) => {
        return new Promise((resolve/*, reject*/) => {
            const link = document.createElement('a');
            const token = localStorageService.getAccessToken();
            link.setAttribute("href",config.apiEndPoint+'/filemanager/download?file=' + encodeURI(filePath) + "&token=" + encodeURI(token));
            link.setAttribute("target", "_blank");
            let filename = path.basename(filePath);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            resolve();
           
        });
    }

}

export default filemanager;

<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <messageBox ref="msgBox" />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1"><v-icon large>mdi-dropbox</v-icon></v-col>
                <v-col
                  align="center"
                  cols="10"
                  class="card-title-text"
                  style="font-size: 20px"
                  >{{ editing ? "Modifica ticket" : "Apri ticket" }}</v-col
                >
                <v-col cols="1">
                  <v-btn icon>
                    <v-icon @click="dismiss" large>mdi-close</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row>
                  <v-col>
                    <v-form class="mt-5" ref="user-editor-form">
                      <ValidationProvider
                        :immediate="true"
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          outlined
                          v-model="ticket.title"
                          label="Nome"
                          :error-messages="errors"
                          :success="valid"
                          dense
                          required
                        ></v-text-field>
                      </ValidationProvider>
                      <ValidationProvider
                        :immediate="true"
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <v-select
                          outlined
                          v-model="ticket.severity"
                          :items="severities"
                          item-text="value"
                          item-value="id"
                          label="Livello"
                          :error-messages="errors"
                          :success="valid"
                          dense
                          required
                        ></v-select>
                      </ValidationProvider>
                      <ValidationProvider
                        :immediate="true"
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <v-textarea
                          outlined
                          :error-messages="errors"
                          :success="valid"
                          label="Descrizione"
                          v-model="ticket.content"
                        ></v-textarea
                      ></ValidationProvider>
                    </v-form>
                  </v-col>
                </v-row>

                <v-row class="text-center">
                  <v-col>
                    <v-btn
                      :disabled="invalid || !validated"
                      width="120"
                      style="color:white;"
                      @click="confirm()"
                      class="ma-2"
                      color="primary"
                      >Conferma</v-btn
                    >
                    <v-btn
                      dark
                      width="120"
                      @click="dismiss()"
                      class="ma-2"
                      color="secondary"
                      >Annulla</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      openDialog: false,
      editing: false,
      dialogResolve: null,
      dialogReject: null,
      ticket: {
        title: null,
        severity: 1,
        content : null
      },
      severities: [
        { id: 1, value: "Nota" },
        { id: 2, value: "Allerta" },
        { id: 3, value: "Grave" }
      ]
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    messageBox
  },

  methods: {
    confirm() {
      this.dialogResolve(this.ticket);
      this.openDialog = false;
    },

    dismiss() {
      this.dialogReject();
      this.openDialog = false;
    },

    show(editing) {
      return new Promise((resolve, reject) => {
        if (!editing)
          this.ticket = {
            title: null,
            severity: 1,
            content : null
          };
        this.editing = editing;
        this.openDialog = true;
        this.dialogReject = reject;
        this.dialogResolve = resolve;
      });
    }
  }
};
</script>

<template>
  <v-container justify-center id="login" tag="section">
    <ValidationObserver ref="pwdObs" v-slot="{ invalid, validated }">
      <v-dialog
        overlay-opacity="0.6"
        max-width="400"
        :value="passwordDialog"
        @input="handlePasswordInput()"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-lock</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 20px"
                      >Cambio password</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="closeDialog" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>
                <v-card-text>
                  <form>
                    <div style="height:10px" />
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        color="secondary"
                        v-model="current_password"
                        label="Password attuale"
                        prepend-icon="mdi-lock"
                        :append-icon="
                          password_view_old ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="
                          () => (password_view_old = !password_view_old)
                        "
                        :type="password_view_old ? 'password' : 'text'"
                        :error-messages="errors"
                        :success="valid"
                        required
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      vid="password_confirm"
                    >
                      <v-text-field
                        color="secondary"
                        v-model="password"
                        label="Nuova password"
                        prepend-icon="mdi-lock"
                        :append-icon="
                          password_view_new ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="
                          () => (password_view_new = !password_view_new)
                        "
                        :type="password_view_new ? 'password' : 'text'"
                        :error-messages="errors"
                        :success="valid"
                        required
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      rules="required|confirmed:password_confirm"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        color="secondary"
                        v-model="repeat_password"
                        label="Conferma nuova password"
                        prepend-icon="mdi-lock"
                        :append-icon="
                          password_view_confirm ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="
                          () => (password_view_confirm = !password_view_confirm)
                        "
                        :type="password_view_confirm ? 'password' : 'text'"
                        :error-messages="errors"
                        :success="valid"
                        required
                      />
                    </ValidationProvider>
                    <div style="height:10px" />
                    <div class="text-center">
                      <v-btn
                        @click="handlePasswordChange()"
                        :disabled="invalid || !validated"
                        color="secondary"
                        >Conferma cambio Password</v-btn
                      >
                    </div>
                  </form>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
      <v-dialog
        overlay-opacity="0.6"
        max-width="400"
        persistent
        :value="cropperDialog"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-account</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 20px"
                      >Posizionamento</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="closeCrop" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>
                <v-card-text>
                  <div v-show="croppieVisible">
                    <v-btn small text icon @click="rotate(-90)"
                      ><v-icon>mdi-rotate-right</v-icon></v-btn
                    >
                    <v-btn small text icon @click="rotate(90)"
                      ><v-icon>mdi-rotate-left</v-icon></v-btn
                    >
                    <vue-croppie
                      ref="croppieRef"
                      :enableOrientation="true"
                      :enable-resize="false"
                      :boundary="{ width: 128, height: 128 }"
                      :viewport="{ width: 128, height: 128, type: 'circle' }"
                    />
                  </div>
                  <div v-show="!croppieVisible">
                    <v-progress-circular
                      :enableExif="true"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="light-blue"
                    ></v-progress-circular>
                    <div style="height:20px" />
                  </div>
                  <!-- the result -->
                  <img v-bind:src="croppedAvatar" />
                  <v-btn
                    class="mr-2"
                    v-show="croppieVisible && !cropping"
                    @click="crop()"
                    color="primary"
                    >OK</v-btn
                  >
                  <v-btn
                    class="ml-2"
                    v-show="croppieVisible && !cropping"
                    @click="closeCrop()"
                    color="secondary"
                    dark
                    >Annulla</v-btn
                  >
                  <v-progress-circular
                    v-show="cropping"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="light-blue"
                  ></v-progress-circular>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>

      <v-dialog
        overlay-opacity="0.6"
        max-width="500"
        :value="badPasswordDialog"
        @input="$emit('input')"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-error</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 20px"
                      >Errore nel cambio della password</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="badPasswordDialog = false" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>

                <v-card-text class="text-center">
                  Vecchia password non corretta
                  <div style="height:10px" />
                  <v-btn
                    @click="badPasswordDialog = false"
                    dark
                    color="secondary"
                    >Chiudi</v-btn
                  >
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
      <v-dialog
        overlay-opacity="0.6"
        max-width="400"
        persistent
        :value="dataChangedDialog"
        @input="dismissDataChangedDialog()"
      >
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-account</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 20px"
                    >
                      CAMBIO DATI PROFILO</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="dismissDataChanged" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>

                <v-card-text class="text-center">
                  I dati del profilo sono stati cambiati. E' necessario
                  effettuare di nuovo il login!
                  <div style="height:10px" />
                  <v-btn @click="dismissDataChanged()" color="secondary" dark
                    >LOGOUT</v-btn
                  >
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>
    </ValidationObserver>

    <v-row v-if="!fetchingData" justify="center">
      <base-material-card
        color="primary"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template v-slot:heading>
          <div class="text-center">
            <v-icon>mdi-account</v-icon>
            Ciao {{ getUsername }}
          </div>
        </template>
        <v-card-text class="text-center">
          <v-row align="center">
            <v-col>
              <image-input v-model="avatarLoader">
                <div slot="activator">
                  <v-btn text icon color="primary"
                    ><v-icon color="gray" size="32">mdi-camera</v-icon></v-btn
                  >
                  <p>Carica immagine</p>
                </div>
              </image-input>
            </v-col>
            <v-col>
              <v-avatar size="128px" v-ripple v-if="croppedAvatar" class="mb-3">
                <img :src="croppedAvatar" />
              </v-avatar>
              <v-avatar
                v-if="!hasGravatar && !croppedAvatar"
                color="indigo"
                size="128px"
                v-ripple
                class="mb-3"
              >
                <span class="white--text headline">{{
                  getProfileLetters
                }}</span>
              </v-avatar>
              <v-avatar
                v-if="hasGravatar && !croppedAvatar"
                size="128px"
                v-ripple
                class="mb-3"
              >
                <img :src="getGravatar()" />
              </v-avatar>
            </v-col>
            <v-col>
              <v-btn
                :disabled="gravatarMode == 1"
                @click="useGravatar()"
                text
                icon
                color="primary"
                ><v-icon color="gray" size="32"
                  >mdi-account-circle</v-icon
                ></v-btn
              >
              <p>Usa Gravatar</p>
            </v-col>
          </v-row>

          <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
            <ValidationProvider
              immediate
              rules="required"
              v-slot="{ errors, valid }"
            >
              <v-text-field
                color="secondary"
                v-model="first_name"
                label="Nome"
                prepend-icon="mdi-account"
                :error-messages="errors"
                :success="valid"
                required
              />
            </ValidationProvider>
            <ValidationProvider
              immediate
              rules="required"
              v-slot="{ errors, valid }"
            >
              <v-text-field
                color="secondary"
                v-model="last_name"
                label="Cognome"
                prepend-icon="mdi-account"
                :error-messages="errors"
                :success="valid"
                required
              />
            </ValidationProvider>

            <ValidationProvider
              immediate
              rules="required|email"
              v-slot="{ errors, valid }"
            >
              <v-text-field
                color="secondary"
                v-model="email"
                label="E-mail"
                prepend-icon="mdi-email"
                :error-messages="errors"
                :success="valid"
                required
              />
            </ValidationProvider>

            <span v-if="new_mail_error" style="color:red"
              >E-mail non disponibile</span
            >
            <div style="height:10px" />
            <v-btn
              @click="updateProfile()"
              :disabled="invalid || !validated"
              color="primary"
              dark
              >Aggiorna</v-btn
            >
            <div style="height:10px" />
            <v-btn dark @click="showPasswordModal()" color="secondary"
              >Cambia password</v-btn
            >
          </ValidationObserver>
        </v-card-text>
      </base-material-card>
    </v-row>

    <v-row v-if="fetchingData" justify="center">
      <v-progress-circular
        :indeterminate="true"
        :rotate="0"
        :size="32"
        :width="4"
        color="light-blue"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<style scoped>
.profile-circle {
  background: #eb6e39;
  color: #ffffff;
  vertical-align: middle;
  width: 128px;
  height: 128px;
  min-width: 128px;
  min-height: 128px;
  font-size: 30px;
  border-radius: 50%;
}
</style>

<script>
import userManager from "../apis/users";
import ImageUpload from "../components/ImageUpload.vue";
import avatars from "../services/avatars";
import localStorageService from "../services/localStorageService";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      password_view_old: String,
      password_view_new: String,
      password_view_confirm: String,
      repeat_password: "",
      password: "",
      current_password: "",
      email: "",
      last_name: "",
      first_name: "",
      fetchingData: false,
      new_mail_error: false,
      updatingProfile: false,
      passwordDialog: false,
      badPasswordDialog: false,
      dataChangedDialog: false,

      gravatar: null,
      avatarLoader: null,
      savingAvatar: false,
      savedAvatar: false,

      cropperDialog: false,
      cropperDialogURL: false,
      croppedAvatar: null,
      croppieVisible: false,
      cropping: false,

      avatarChanged: false,
      gravatarMode: null, // Letters
    };
  },

  mounted() {
    this.fetchingData = true;
    this.last_name = this.$store.state.user.last_name;
    this.first_name = this.$store.state.user.first_name;
    this.email = this.$store.state.user.email;
    this.gravatar = this.$store.state.user.avatar;
    this.gravatarMode = this.$store.state.user.gravatar;
    this.fetchingData = false;
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    ImageInput: ImageUpload,
  },

  watch: {
    avatarLoader: {
      handler: function() {
        if (this.avatarLoader) {
          this.saved = false;
          this.cropperDialog = true;
          this.croppieVisible = false;
          this.croppedAvatar = null;
          let that = this;
          setTimeout(function() {
            that.croppieVisible = true;
            that.$refs.croppieRef.bind({
              url: that.avatarLoader.imageURL,
            });
          }, 400);
        } else {
          this.croppieVisible = false;
        }
      },
      deep: true,
    },

    email: {
      handler: function() {
        if (!this.croppedAvatar && this.gravatarMode == 1) {
          clearTimeout(this._debounceTimer);
          this._debounceTimer = setTimeout(() => {
            avatars
              .fetchGravatar(this.email)
              .then((result) => {
                this.gravatar = result;
              })
              .catch(() => {
                this.gravatar = null;
              });
          }, 300);
        }
      },
    },
  },

  computed: {
    isLoggedIn: function() {
      return this.$store.state.user;
    },

    getUsername: function() {
      return this.$store.state.user.first_name;
    },

    getProfileLetters: function() {
      if (this.first_name && this.last_name) {
        if (this.first_name.length && this.last_name.length) {
          return (
            this.first_name.toUpperCase()[0] + this.last_name.toUpperCase()[0]
          );
        }
      }
      return "";
    },

    hasGravatar: function() {
      return this.gravatar ? true : false;
    },
  },
  methods: {
    closeDialog() {
      this.passwordDialog = false;
    },

    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },

    getGravatar: function() {
      return "data:image/jpeg;base64," + this.gravatar;
    },

    crop() {
      let options = {
        format: "jpeg",
        size: { width: 128, height: 128 },
      };
      this.cropping = true;

      this.$refs.croppieRef.result(options, (output) => {
        userManager
          .uploadAvatar(output.replace("data:image/jpeg;base64,", ""))
          .then(() => {
            this.croppedAvatar = output;
            this.cropperDialog = false;
            this.croppieVisible = true;
            this.avatarChanged = true;
            this.gravatar = null;
            this.gravatarMode = 0;
            this.cropping = false;
          })
          .catch((err) => {
            console.log(err);
            this.cropping = false;
          });
      });
    },

    closeCrop() {
      this.cropperDialog = false;
      this.croppieVisible = true;
      this.croppedAvatar = null;
    },

    useGravatar() {
      // Fetch a new gravatar using the email address
      avatars
        .fetchGravatar(this.email)
        .then((result) => {
          this.gravatar = result;
          this.croppedAvatar = null;
          this.avatarChanged = true;
          this.gravatarMode = 1;
        })
        .catch(() => {
          this.gravatar = null;
        });
    },

    handlePasswordInput: function() {
      this.$emit("input");
      this.passwordDialog = false;
      this.$refs.obs.validate(); // Revalidate the bottom form observer
      this.$refs.pwdObs.reset();
    },

    checkFormEmail: function() {
      return new Promise((resolve, reject) => {
        if (this.email == this.$store.state.user.email) {
          resolve();
        } else {
          this.new_mail_error = false;
          userManager
            .checkMail(this.email, this.$store.state.user.id)
            .then((count) => {
              if (count != 0) {
                if (count > 0) {
                  // Mail not valid, give a warning
                  this.new_mail_error = true;
                  reject();
                } else {
                  this.new_mail_error = true;
                  reject();
                }
              } else {
                resolve();
              }
            })
            .catch((err) => {
              this.new_mail_error = true;
              reject(err);
            });
        }
      });
    },

    dismissDataChanged: function() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },

    updateProfile: function() {
      this.updatingProfile = true;
      this.checkFormEmail()
        .then(() => {
          if (!this.gravatar && !this.croppedAvatar) this.gravatarMode = null;
          else if (this.croppedAvatar) this.gravatarMode = 0;
          else this.gravatarMode = 1;
          this.$store.state.user.gravatar = this.gravatarMode;
          avatars.removeAvatarFromCache(this.$store.state.user.id);

          userManager
            .updateProfile({
              gravatar: this.gravatarMode,
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
              id: this.$store.state.user.id,
            })
            .then(() => {
              this.updatingProfile = false;

              this.$store.state.user.gravatar = this.gravatarMode;

              if (this.gravatarMode == 0) {
                let filteredAvatar = this.croppedAvatar.replace(
                  "data:image/jpeg;base64,",
                  ""
                );
                this.$store.state.user.avatar = (" " + filteredAvatar).slice(1); // Copy to force
              } else if (this.gravatarMode == 1) {
                this.$store.state.user.avatar = (" " + this.gravatar).slice(1); // Copy to force
              }

              localStorageService.setGravatar(this.gravatarMode);
              localStorageService.setAvatar(this.$store.state.user.avatar);
              this.$store.state.user.first_name = this.first_name;
              this.$store.state.user.last_name = this.last_name;

              if (this.email != this.$store.state.user.email) {
                this.dataChangedDialog = true;
                this.$store.state.user.email = this.email;
              } else {
                this.$router.push("/");
              }
            })
            .catch((err) => {
              this.updatingProfile = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.updatingProfile = false;
        });
    },

    handlePasswordChange: function() {
      userManager
        .changePassword(this.current_password, this.password)
        .then((result) => {
          if (result.data.responseData.result) {
            this.$refs.pwdObs.reset();
            this.passwordDialog = false;
          } else {
            this.$refs.pwdObs.reset();
            this.passwordDialog = false;
            this.badPasswordDialog = true;
          }
        })
        .catch((err) => {
          this.$refs.pwdObs.reset();
          this.passwordDialog = false;
          this.badPasswordDialog = true;
          console.log(err);
        });
    },

    showPasswordModal: function() {
      this.current_password = "";
      this.password = "";
      this.repeat_password = "";
      this.passwordDialog = true;
    },
  },
};
</script>

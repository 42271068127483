<template>

  <v-container justify-center fluid grid-list-xl>

    <v-layout justify-center wrap>

      <v-flex md12>

        <v-dialog
          overlay-opacity="0.6"
          max-width="600"
          :value="changeOrderStateDialog"
          persistent
        >

          <v-container fill-height fluid grid-list-xl>

            <v-layout justify-center wrap>

              <v-flex md12>

                <base-material-card color="primary">

                  <template v-slot:heading>

                    <v-row class="pa-0">

                      <v-col cols="1" class="pa-0">

                        <v-icon large> mdi-water-plus-outline </v-icon>

                      </v-col>

                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                      >
                         {{ $t("order-state-editor-title-modify") }}
                      </v-col>

                      <v-col cols="1" class="pa-0">

                        <v-icon @click="changeOrderStateDialog = false" large>
                           mdi-close
                        </v-icon>

                      </v-col>

                    </v-row>

                  </template>

                  <div style="height:10px" />

                  <v-select
                    :label="$t('order-state-editor-hint-type')"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    v-model="orderStateDialogValue"
                    :items="orderStates"
                  />

                  <v-btn @click="handleChangeOrderState()" color="primary">
                     {{ $t("profile-change-pwd-confirm") }}
                  </v-btn>

                </base-material-card>

              </v-flex>

            </v-layout>

          </v-container>

        </v-dialog>

        <messageBox ref="msgBox" />

        <orderEditor
          @orderConfigured="handleOrder"
          v-model="orderEditorDialog"
          ref="orderEditor"
        />

        <v-btn v-if="!loadingFilters" @click="addOrder()" color="primary">
           {{ $t("warehouse-ordermng-add-order") }}
        </v-btn>

        <div class="vertical-spacer" />

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >

          <template v-slot:after-heading>

            <div class="display-1 font-weight-light card-header">
               {{ $t("warehouse-ordermng-title") }}
            </div>

          </template>

          <div class="vertical-spacer" />

          <filtersBar
            :filters="filters"
            :disabled="loadingOrders"
            @onFiltersLoading="onFiltersLoading"
            @onFiltersLoaded="onFiltersLoaded"
            :showLoader="true"
          />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :server-items-length="totalOrders"
            :loading="loadingOrders"
            :footer-props="dataTableFooterOptions"
          >

            <template v-slot:item.id="{ item }"> {{ item.id }}</template>

            <template v-slot:item.actions="{ item }">

              <v-row>

                <v-col class="text-center" style="min-width:96px;">

                  <v-tooltip bottom>

                    <template v-slot:activator="{ on }">

                      <v-btn
                        x-small
                        text
                        icon
                        color="green darken-2"
                        @click="downloadOrderResume(item)"
                        v-on="on"
                      >

                        <v-icon>mdi-download</v-icon>

                      </v-btn>

                    </template>

                    <span>{{ $t("warehouse-ordersmng-download-resume") }}</span>

                  </v-tooltip>

                  <v-tooltip v-if="item.state < 3" bottom>

                    <template v-slot:activator="{ on }">

                      <v-btn
                        x-small
                        text
                        icon
                        color="green darken-2"
                        @click="editItem(item)"
                        v-on="on"
                      >

                        <v-icon>mdi-pencil</v-icon>

                      </v-btn>

                    </template>

                    <span>{{ $t("warehouse-ordermng-edit-order") }}</span>

                  </v-tooltip>

                  <v-tooltip
                    v-if="$store.state.hasRight('179') && item.state != 5"
                    bottom
                  >

                    <template v-slot:activator="{ on }">

                      <v-btn
                        x-small
                        text
                        icon
                        color="red darken-1"
                        @click="deleteItem(item)"
                        v-on="on"
                      >

                        <v-icon>mdi-delete</v-icon>

                      </v-btn>

                    </template>

                    <span>{{ $t("warehouse-ordermng-remove-order") }}</span>

                  </v-tooltip>

                </v-col>

              </v-row>

            </template>

            <template v-slot:item.creation_date="{ item }">
               {{ item.creation_date | toLocaleDate }}
            </template>

            <template v-slot:item.notes="{ item }">

              <v-tooltip v-if="item.notes" bottom>

                <template v-slot:activator="{ on }">

                  <v-icon v-on="on" slot="activator" color="cyan">
                     mdi-information-outline
                  </v-icon>

                </template>

                <span>{{ item.notes }}</span>

              </v-tooltip>

            </template>

            <template v-slot:item.total_gross="{ item }">

              <span v-if="item.total_gross">
                 {{ item.total_gross.toFixed(2) | toCurrency }} €
              </span>

            </template>

            <template v-slot:item.total_net="{ item }">

              <span v-if="item.total_net">
                 {{ item.total_net.toFixed(2) | toCurrency }} €
              </span>

            </template>

            <template v-slot:item.user="{ item }">

              <div class="body-2">
                 {{ item.user_first_name + " " + item.user_last_name }}
              </div>

            </template>

            <template v-slot:item.business_name="{ item }">

              <div class="body-2">

                <v-icon :color="item.reference_color">mdi-circle</v-icon>
                 {{( item.business_name ? item.business_name : item.first_name +
                " " + item.last_name )}}
              </div>

              <div class="body-3">{{ item.ad_address }}</div>

            </template>

            <template v-slot:item.state="{ item }">
               {{ getOrderState(item.state) }}
              <v-tooltip bottom>

                <template v-slot:activator="{ on }">

                  <v-btn
                    v-if="item.state != 5"
                    x-small
                    text
                    icon
                    color="orange darken-2"
                    @click="setOrderState(item)"
                    v-on="on"
                  >

                    <v-icon>mdi-pencil</v-icon>

                  </v-btn>

                </template>

                <span>{{ $t("warehouse-ordermng-change-state") }}</span>

              </v-tooltip>

            </template>

          </v-data-table>

        </base-material-card>

      </v-flex>

    </v-layout>

  </v-container>

</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import orderEditor from "../components/WarehouseOrderEditor.vue";
import messageBox from "../components/MessageBox";
import filtersBar from "../components/FiltersBar.vue";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      orderEditorDialog: false,
      changeOrderStateDialog: false,
      orderStateDialogItem: null,
      orderStateDialogValue: null,

      options: {
        sortBy: ["o1.id"],
        sortDesc: [true]
      },

      staticData: staticData,
      headers: [],
      perPage: staticData.defautPerPage,
      totalOrders: 0,
      filter: "",

      filters: {
        filterByUserId: null,
        filterByCustomerId: null,
        filterByPeriod: 5,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByCustomerCategory: null,
        filterByProductCategory: null,
        filterByOrderState: null,
        filterByProductId: null,
        filterByWarehouse: null
      },
      orderStates: [],
      orders: [],
      loadingOrders: false,
      loadingFilters: false
    };
  },

  mounted() {
    this.orderStates = [
      {
        id: null,
        name: this.$t("gbl-all-male")
      },
      ...this.staticData.orderStates
    ];

    this.headers = [
      {
        text: this.$t("ordersmng-tbl-id"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("ordersmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("ordersmng-tbl-user"),
        align: "left",
        sortable: true,
        value: "user"
      },
      {
        text: this.$t("ordersmng-tbl-date"),
        align: "left",
        sortable: true,
        value: "creation_date"
      },
      {
        text: this.$t("ordersmng-tbl-warehouse"),
        align: "left",
        sortable: true,
        value: "warehouse"
      },

      {
        text: this.$t("ordersmng-tbl-state"),
        align: "left",
        sortable: true,
        value: "state"
      },

      {
        text: this.$t("ordersmng-tbl-gross"),
        align: "left",
        sortable: true,
        value: "total_gross"
      },
      {
        text: this.$t("ordersmng-tbl-net"),
        align: "left",
        sortable: true,
        value: "total_net"
      },
      {
        text: this.$t("ordersmng-tbl-amount"),
        align: "left",
        sortable: true,
        value: "quantity"
      },

      {
        text: this.$t("ordersmng-tbl-notes"),
        align: "center",
        sortable: true,
        value: "notes"
      },
      { value: "actions", sortable: false, text: "" }
    ];
  },

  components: {
    messageBox,
    filtersBar,
    orderEditor
  },

  computed: {
    getPaymentStates() {
      let ar = [...this.paymentStates].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return ar;
    },

    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    }
  },

  watch: {
    options: {
      handler() {
        this.fetchOrders();
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchOrders();
        }, 300);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchOrders();
        }, 500);
      },
      deep: true
    }
  },

  methods: {
    downloadOrderResume(item) {
      ordersManager.downloadWarehouseOrderResume(item.id);
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    getOrderState(state) {
      return staticData.orderStates[state - 1].name;
    },

    buildCtx() {
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByOrderState = this.filters.filterByOrderState;
      ctx.filterByProductId = this.filters.filterByProductId;
      ctx.filterByWarehouse = this.filters.filterByWarehouse;
      ctx.filterByCustomerCategory = this.filters.filterByCustomerCategory;
      ctx.filterByProductCategory = this.filters.filterByProductCategory;

      return ctx;
    },

    fetchOrders() {
      this.loadingOrders = true;
      this.totalOrders = 0;
      this.orders = [];
      let ctx = this.buildCtx();
      ordersManager
        .fetchWarehouseOrders(ctx)
        .then(result => {
          this.totalOrders = result.totalCount;
          this.orders = result.items;
          this.loadingOrders = false;
        })
        .catch(err => {
          this.loadingOrders = false;
          console.log(err);
        });
    },

    handleOrder(event) {
      if (event.editingOrder) {
        let order = event.order;
        ordersManager
          .updateWarehouseOrder(order)
          .then(() => {
            this.fetchOrders();
            if (!event.continueInsert) this.orderEditorDialog = false;
            else this.$refs.orderEditor.resetAfterAdd();
          })
          .catch(err => {
            this.$refs.msgBox
              .show(err, this.$t("ordersmng-error-title"), true)
              .then(() => {})
              .catch(() => {});
            console.log(err);
            this.$refs.orderEditor.resetAfterAdd();
          });
      } else {
        let order = event.order;
        ordersManager
          .addWarehouseOrder(order)
          .then(() => {
            this.fetchOrders();
            if (!event.continueInsert) this.orderEditorDialog = false;
            else this.$refs.orderEditor.resetAfterAdd();
          })
          .catch(err => {
            this.$refs.msgBox
              .show(err, this.$t("ordersmng-error-title"), true)
              .then(() => {})
              .catch(() => {});

            console.log(err);
            this.$refs.orderEditor.resetAfterAdd();
          });
      }
    },

    addOrder() {
      this.$refs.orderEditor
        .setModalOrder(
          {
            customer_id: null,
            state: 1,
            notes: ""
          },
          false
        )
        .then(() => {
          this.orderEditorDialog = true;
        });
    },

    deleteItem(item) {
      let orderId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("ordersmng-delete-text"),
          this.$t("ordersmng-delete-title")
        )
        .then(() => {
          ordersManager
            .removeWarehouseOrder(orderId)
            .then(() => {
              this.fetchOrders();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let order = JSON.parse(JSON.stringify(item));
      this.$refs.orderEditor.setModalOrder(order, true).then(() => {
        this.orderEditorDialog = true;
      });
    },

    setOrderState(item) {
      this.changeOrderStateDialog = true;
      this.orderStateDialogValue = item.state;
      this.orderStateDialogItem = item;
    },

    setOrderPaymentState(item) {
      this.changeOrderPaymentStateDialog = true;
      this.orderPaymentStateDialogValue = item.payment_state;
      this.orderPaymentStateDialogItem = item;
    },

    async handleChangeOrderState() {
      this.changeOrderStateDialog = false;
      if (this.orderStateDialogValue == this.orderStateDialogItem.state) {
        return;
      }
      if (this.orderStateDialogValue == 5) {
        try {
          await this.$refs.msgBox.show(
            this.$t("warehouse-ordermng-confirm-completed"),
            this.$t("warehouse-ordermng-confirm-completed-title")
          );
        } catch (err) {
          return;
        }
      }

      ordersManager
        .changeWarehouseOrderStatus(
          this.orderStateDialogItem.id,
          this.orderStateDialogValue
        )
        .then(() => {
          this.orderStateDialogItem.state = this.orderStateDialogValue;
          this.changeOrderStateDialog = false;
          this.orderStateDialogItem = null;
          this.orderStateDialogValue = null;
          this.fetchOrders();
        })
        .catch(err => {
          console.log(err);
          this.changeOrderStateDialog = false;
          this.orderStateDialogItem = null;
          this.orderStateDialogValue = null;
        });
    },

    handleChangePaymentOrderState() {
      ordersManager
        .changePaymentStatus(
          this.orderPaymentStateDialogItem.id,
          this.orderPaymentStateDialogValue
        )
        .then(() => {
          this.orderPaymentStateDialogItem.payment_state = this.orderPaymentStateDialogValue;
          this.changeOrderPaymentStateDialog = false;
          this.orderPaymentStateDialogItem = null;
          this.orderPaymentStateDialogValue = null;
        })
        .catch(err => {
          console.log(err);
          this.changeOrderPaymentStateDialog = false;
          this.orderPaymentStateDialogItem = null;
          this.orderPaymentStateDialogValue = null;
        });
    }
  }
};
</script>


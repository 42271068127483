<template>

  <v-container justify-center fluid grid-list-xl>

    <v-layout justify-center wrap>

      <v-flex md12>

        <messageBox ref="msgBox" />

        <v-dialog
          overlay-opacity="0.6"
          max-width="600"
          :value="addAssignmentDialog"
          persistent
        >

          <ValidationObserver ref="obs" v-slot="{ invalid, validated }">

            <v-container fill-height fluid grid-list-xl>

              <v-layout justify-center wrap>

                <v-flex md12>

                  <base-material-card color="primary">

                    <template v-slot:heading>

                      <v-row class="pa-0">

                        <v-col cols="1" class="pa-0">

                          <v-icon large> mdi-location-exit </v-icon>

                        </v-col>

                        <v-col
                          align="center"
                          cols="10"
                          class="pa-0 card-title-text"
                          style="font-size: 25px"
                        >
                           {{ $t("assignment-title-add") }}
                        </v-col>

                        <v-col cols="1" class="pa-0">

                          <v-icon @click="addAssignmentDialog = false" large>
                             mdi-close
                          </v-icon>

                        </v-col>

                      </v-row>

                    </template>

                    <div style="height:20px" />

                    <v-row>

                      <v-col>

                        <v-autocomplete
                          :disabled="loadingFilters"
                          clearable
                          dense
                          no-filter
                          v-model="flt_customer"
                          :items="flt_customers"
                          :loading="isLoadingCustomer"
                          :search-input.sync="searchCustomerInput"
                          outlined
                          item-text="display_name"
                          item-value="id"
                          :label="$t('filters-customers')"
                          return-object
                        />

                      </v-col>

                    </v-row>

                    <div style="height:10px" />

                    <v-row>

                      <v-col>

                        <v-autocomplete
                          :disabled="loadingFilters || !flt_customer"
                          clearable
                          dense
                          no-filter
                          v-model="flt_product"
                          :items="flt_products"
                          :loading="isLoadingProducts"
                          :search-input.sync="searchProductInput"
                          outlined
                          item-text="name"
                          item-value="id"
                          :label="$t('filters-product')"
                          return-object
                        ></v-autocomplete>

                      </v-col>

                    </v-row>

                    <div style="height:10px" />

                    <v-row>

                      <v-col>

                        <ValidationProvider
                          :immediate="true"
                          rules="required|min_value:1"
                          v-slot="{ errors, valid }"
                        >

                          <v-text-field
                            v-model="flt_amount"
                            dense
                            :error-messages="errors"
                            :success="valid"
                            outlined
                            required
                            :label="$t('filters-amount')"
                            :immediate="true"
                          ></v-text-field>

                        </ValidationProvider>

                      </v-col>

                    </v-row>

                    <v-btn
                      :disabled="
                        !flt_product ||
                          !flt_customer ||
                          !flt_amount ||
                          flt_amount <= 0
                      "
                      @click="handleAddAssignment()"
                      color="primary"
                    >
                       {{ $t("add-assignment") }}
                    </v-btn>

                  </base-material-card>

                </v-flex>

              </v-layout>

            </v-container>

          </ValidationObserver>

        </v-dialog>

        <v-btn v-if="!loadingFilters" @click="addAssegnation()" color="primary">
           {{ $t("warehouse-assignments-add-assignment") }}
        </v-btn>

        <div class="vertical-spacer" />

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-location-exit"
          inline
          class="px-5 py-3"
        >

          <template v-slot:after-heading>

            <div class="display-1 font-weight-light card-header">
               {{ $t("warehouse-assignments-title") }}
            </div>

          </template>

          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :server-items-length="totalOrders"
            :loading="loadingOrders"
            :footer-props="dataTableFooterOptions"
          >

            <template v-slot:item.id="{ item }"> {{ item.id }}</template>

            <template v-slot:item.actions="{ item }">

              <v-row>

                <v-col class="text-center" style="min-width:96px;">

                  <v-tooltip bottom>

                    <template v-slot:activator="{ on }">

                      <v-btn
                        x-small
                        text
                        icon
                        color="red darken-1"
                        @click="deleteItem(item)"
                        v-on="on"
                      >

                        <v-icon>mdi-delete</v-icon>

                      </v-btn>

                    </template>

                    <span>{{ $t("warehouse-ordermng-remove-order") }}</span>

                  </v-tooltip>

                </v-col>

              </v-row>

            </template>

            <template v-slot:item.creation_date="{ item }">
               {{ item.creation_date | toLocaleDate }}
            </template>

            <template v-slot:item.business_name="{ item }">

              <div class="body-2">

                <v-icon :color="item.reference_color">mdi-circle</v-icon>
                 {{ (item.business_name ? item.business_name : item.first_name +
                " " + item.last_name) }}
              </div>

            </template>

          </v-data-table>

        </base-material-card>

      </v-flex>

    </v-layout>

  </v-container>

</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import messageBox from "../components/MessageBox";
import config from "@/config";
import productsManager from "../apis/products";
import customersManager from "../apis/customers";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      orderEditorDialog: false,
      changeOrderStateDialog: false,
      orderStateDialogItem: null,
      orderStateDialogValue: null,

      options: {
        sortBy: ["ass1.creation_date"],
        sortDesc: [true]
      },

      staticData: staticData,
      headers: [],
      perPage: staticData.defautPerPage,
      totalOrders: 0,
      addAssignmentDialog: false,
      filters: {
        filterByCustomerId: null,
        filterByPeriod: 5,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null
      },
      orderStates: [],
      orders: [],
      loadingOrders: false,
      loadingFilters: false,

      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,

      flt_product: null,
      flt_products: [],
      searchProductInput: null,
      isLoadingProducts: false,

      flt_amount: 0
    };
  },

  mounted() {
    this.orderStates = [
      {
        id: null,
        name: this.$t("gbl-all-male")
      },
      ...this.staticData.orderStates
    ];

    this.headers = [
      {
        text: this.$t("warehouse-assignments-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("warehouse-assignments-tbl-product"),
        align: "left",
        sortable: true,
        value: "product"
      },

      {
        text: this.$t("warehouse-assignments-tbl-date"),
        align: "left",
        sortable: true,
        value: "creation_date"
      },

      {
        text: this.$t("warehouse-assignments-tbl-amount"),
        align: "left",
        sortable: true,
        value: "amount"
      },

      { value: "actions", sortable: false, text: "" }
    ];
  },

  components: {
    messageBox,
    ValidationProvider,
    ValidationObserver
  },

  watch: {
    searchProductInput(val) {
      clearTimeout(this._searchProductInput);
      if (val) {
        this._searchProductInput = setTimeout(() => {
          this.isLoadingProducts = true;
          this.fetchFilterProducts(val)
            .then(res => {
              this.flt_products = res.products;
              this.isLoadingProducts = false;
            })
            .catch(() => {
              this.isLoadingProducts = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.filters.filterByProductId = null;
      }
    },

    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val && !this.flt_customers.some(item => item.display_name === val)) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;

          this.fetchFilterCustomers(val)
            .then(res => {
              this.flt_customers = res.customers.map(x => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.filters.filterByCustomerId = null;
      }
    },

    options: {
      handler() {
        this.fetchAssignments();
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchAssignments();
        }, 500);
      },
      deep: true
    }
  },

  methods: {
    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("warehouse-assignments-remove-text"),
          this.$t("warehouse-assignments-remove-title")
        )
        .then(() => {
          ordersManager
            .removeWarehouseAssignment(item.id)
            .then(() => {
              this.fetchAssignments();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterProducts(filter) {
      return new Promise((resolve, reject) => {
        productsManager
          .fetchFilterProducts(filter, null, this.flt_customer.id)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    buildCtx() {
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;

      return ctx;
    },

    fetchAssignments() {
      this.loadingOrders = true;
      this.totalOrders = 0;
      this.orders = [];
      let ctx = this.buildCtx();
      ordersManager
        .fetchWarehouseAssignments(ctx)
        .then(result => {
          this.totalOrders = result.totalCount;
          this.orders = result.items;
          this.loadingOrders = false;
        })
        .catch(err => {
          this.loadingOrders = false;
          console.log(err);
        });
    },

    addAssegnation() {
      this.flt_amount = 0;
      this.flt_customers = [];
      this.flt_customer = null;
      this.flt_products = [];
      this.flt_product = null;
      this.addAssignmentDialog = true;
    },

    handleAddAssignment() {
      ordersManager
        .addWarehouseAssignment(
          this.flt_customer.id,
          this.flt_product.id,
          this.flt_amount
        )
        .then(() => {
          this.addAssignmentDialog = false;
          this.fetchAssignments();
        })
        .catch(err => {
          this.$refs.msgBox.showError(err);
        });
    }
  }
};
</script>


<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <messageBox ref="msgBox" />
      <userEditor
        @userConfigured="handleUser"
        ref="userEditor"
        v-model="userEditorDialog"
      ></userEditor>

     

      <v-flex md12>
        <v-btn
          v-if="$store.state.hasRight('20')"
          v-on:click="addUser()"
          color="primary"
          >{{ $t("usrmng-add") }}</v-btn
        >
        <base-material-card
          color="primary"
          icon="mdi-account"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("usrmng") }}
            </div>
          </template>
          <v-text-field
            :value="filter"
            @change="(v) => (filter = v)"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="users"
            :options.sync="options"
            :server-items-length="totalUsers"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.first_name="{ item }">
              <v-avatar
                size="36px"
                v-if="checkGravatar(item) && getGravatar(item)"
              >
                <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
              </v-avatar>

              <v-avatar
                v-else-if="getProfileLetters(item)"
                color="blue"
                size="36px"
              >
                <span class="profile-text">{{ getProfileLetters(item) }}</span>
              </v-avatar>

              {{ item.first_name }}
            </template>
            <template v-slot:item.rights="{ item }">
              {{ getRight(item) }}
            </template>
            <template v-slot:item.groups="{ item }">
              <span v-html="formatGroups(item)"></span>
            </template>

            <template v-slot:item.actions="{ item }">
              

              <v-tooltip v-if="$store.state.hasRight('21')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      !item.can_be_edited || item.id == $store.state.user.id
                    "
                    x-small
                    text
                    icon
                    color="primary"
                    @click.stop="editItem(item)"
                    v-on="on"
                    ><v-icon>mdi-account-edit</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-modify-user") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="!item.banned && $store.state.hasRight('22')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      !item.can_be_edited || item.id == $store.state.user.id
                    "
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="banItem(item)"
                    v-on="on"
                    ><v-icon>mdi-account-off</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-ban-user") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="item.banned && $store.state.hasRight('22')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="
                      !item.can_be_edited || item.id == $store.state.user.id
                    "
                    x-small
                    text
                    icon
                    color="success"
                    @click.stop="banItem(item)"
                    v-on="on"
                    ><v-icon>mdi-account</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-unban-user") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="
                  item.can_be_deleted &&
                    item.id != $store.state.user.id &&
                    item.orders_count == 0 &&
                    $store.state.hasRight('23')
                "
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="deleteItem(item)"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-remove-user") }}</span></v-tooltip
              >

              <v-tooltip
                v-if="
                  (!item.can_be_deleted ||
                    item.id == $store.state.user.id ||
                    item.orders_count > 0) &&
                    $store.state.hasRight('23')
                "
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="true"
                    x-small
                    text
                    icon
                    color="error"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-remove-user") }}</span></v-tooltip
              >
              <v-tooltip v-if="$store.state.hasRight('114')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="purple"
                    v-on="on"
                    @click.stop="impersonate(item)"
                    ><v-icon>mdi-ghost</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-impersonate") }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.banned="{ item }">
              {{ item.banned ? $t("gbl-no") : $t("gbl-yes") }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import userManager from "../apis/users";
import staticData from "../apis/staticData";
import rightsServices from "../services/rights";
import messageBox from "../components/MessageBox.vue";
import userEditor from "../components/UserEditor.vue";
import AvatarServices from "../services/avatars";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      users: [],
      groups: {},
      loading: false,
      totalUsers: 0,
      options: {
        sortBy: ["last_name"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: "",

      userEditorDialog: false,
    };
  },

  components: {
    userEditor,
    messageBox
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("usrmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "first_name",
      },
      {
        text: this.$t("usrmng-tbl-surname"),
        align: "center",
        sortable: true,
        value: "last_name",
      },
      {
        text: this.$t("usrmng-tbl-email"),
        align: "center",
        sortable: true,
        value: "email",
      },
      {
        text: this.$t("usrmng-tbl-groups"),
        align: "center",
        sortable: true,
        value: "groups",
      },
      {
        text: this.$t("usrmng-tbl-role"),
        align: "center",
        sortable: true,
        value: "rights",
      },
    ];

    this.headers = [
      ...this.headers,
      {
        text: this.$t("usrmng-tbl-access"),
        align: "center",
        sortable: true,
        value: "banned",
      },
      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    options: {
      handler() {
        this.reloadUsers();
      },
      deep: true,
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadUsers();
      },
    },
  },

  methods: {
    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },

    reloadUsers: function() {
      this.fetchUsers()
        .then((data) => {
          this.users = data.items;
          this.totalUsers = data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.id)
          .then((result) => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    getRight(item) {
      let rights = item.rights.split(",");
      if (rightsServices.hasExactRight(rights, "sa"))
        return this.$t("usrmng-right-sa");
      else if (rightsServices.hasExactRight(rights, "a"))
        return this.$t("usrmng-right-admin");
      else if (rightsServices.hasExactRight(rights, "u"))
        return this.$t("usrmng-right-user");
      else return this.$t("usrmng-right-none");
    },

    formatGroups(item) {
      if (!item.groups) return "";
      let groups = item.groups.split(",");
      let names = [];
      for (let n in groups) {
        let id = groups[n];
        if (this.groups[id]) {
          let name = this.groups[id].name;
          names.push(name);
        }
      }

      return names.join("<BR/>");
    },

    fetchUsers() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        userManager
          .fetchUsers(ctx)
          .then((result) => {
            userManager
              .fetchGroups({})
              .then((groups) => {
                this.groups = {};
                for (let n in groups.items) {
                  let group = groups.items[n];
                  this.groups[group.id] = group;
                }
                this.loading = false;
                resolve(result);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },

    handleUser(event) {
      this.userEditorDialog = false; // Close dialog

      if (event.editingUser) {
        let user = event.user;
        userManager
          .updateUser(user)
          .then(() => {
            this.reloadUsers(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let user = event.user;
        userManager
          .addUser(user)
          .then((res) => {
            if (!res.user && res.mailResult) {
              this.$refs.msgBox.show(
                res.mailResult,
                this.$t("msgbox-error"),
                true
              );
            } else {
              this.reloadUsers(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    banItem(item) {
      userManager
        .banUser(item)
        .then(() => {
          item.banned = !item.banned;
        })
        .catch(() => {});
    },

    deleteItem(item) {
      let userId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("msgbox-confirm-op"),
          this.$t("usrmng-msgbox-confirm-delete")
        )
        .then(() => {
          userManager
            .removeUser(userId)
            .then(() => {
              this.reloadUsers(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    impersonate(item) {
      let userId = item.id;
      userManager
        .impersonateUser(userId)
        .then((res) => {
          this.$store
            .dispatch("impersonate", res)
            .then(() => {
              this.$router.push("/");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editItem(item) {
      let user = JSON.parse(JSON.stringify(item));
      this.$refs.userEditor.resetModal();
      this.$refs.userEditor.setModalUser(user, true, item);
      this.userEditorDialog = true;
    },

    addUser() {
      this.$refs.userEditor.resetModal();
      this.$refs.userEditor.setModalUser(
        {
          first_name: "",
          last_name: "",
          email: "",
          rights: "u",
          groups: "",
          locked_locale: null,
        },
        false,
        null
      );
      this.userEditorDialog = true;
    },
  },
};
</script>

<template>

  <div>

    <v-progress-circular v-if="loadingFilters && showLoader" :indeterminate="true" :rotate="0" :size="32" :width="4"
      color="primary"></v-progress-circular>

    <div v-if="!loadingFilters">

      <v-row dense>

        <v-col>

          <v-select class="minfield" outlined dense :label="$t('filters-period-order-state')" ref="period"
            v-model="filters.filterByOrderState" :items="orders_states" item-text="name" item-value="id"
            :disabled="disabled">

          </v-select>

        </v-col>

        <v-col>

          <v-select class="minfield" outlined dense :label="$t('filters-warehouse')" ref="period"
            v-model="filters.filterByWarehouse" :items="warehouses" item-text="name" item-value="id"
            :disabled="disabled">

          </v-select>

        </v-col>



        <v-col>

          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_customer"
            :items="flt_customers" :loading="isLoadingCustomer" :search-input.sync="searchCustomerInput" outlined
            item-text="display_name" item-value="id" :label="$t('filters-customers')" return-object />

        </v-col>





        <v-col>

          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_user" :items="flt_users"
            :loading="isLoadingUser" :search-input.sync="searchUserInput" outlined item-text="name" item-value="id"
            :label="$t('filters-users')" return-object />

        </v-col>


      </v-row>

      <v-row dense>

        <v-col>

          <v-select class="minfield" outlined dense :label="$t('filters-customer-category')"
            v-model="filters.filterByCustomerCategory" :items="customerCategories" item-text="name" item-value="id"
            :disabled="disabled">

          </v-select>

        </v-col>

        <v-col>

          <v-select class="minfield" outlined dense :label="$t('filters-product-category')"
            v-model="filters.filterByProductCategory" :items="productCategories" item-text="name" item-value="id"
            :disabled="disabled">

          </v-select>

        </v-col>

        <v-col>

          <v-autocomplete :disabled="loadingFilters" clearable dense no-filter v-model="flt_product"
            :items="flt_products" :loading="isLoadingProducts" :search-input.sync="searchProductInput" outlined
            item-text="name" item-value="id" :label="$t('filters-product')" return-object></v-autocomplete>

        </v-col>



        <v-col>

          <v-select class="minfield" outlined dense :label="$t('filters-period-hint')" ref="period"
            v-model="filters.filterByPeriod" :items="getPeriodFilters" item-text="name" item-value="id"
            :disabled="disabled">

          </v-select>

        </v-col>

        <v-col>

          <v-dialog ref="dialog_start_date" v-model="date_start_modal"
            :return-value.sync="filters.filterByCustomDateStart" width="290px">

            <template v-slot:activator="{ on }">

              <v-text-field dense outlined v-model="start_date_formatted" :disabled="disabled"
                :label="$t('filters-startdate-hint')" readonly clearable v-on="on"></v-text-field>

            </template>

            <v-date-picker @input="
              $refs.dialog_start_date.save(filters.filterByCustomDateStart)
              " v-model="filters.filterByCustomDateStart" scrollable :first-day-of-week="1"
              :locale="$store.state.user.locale">

            </v-date-picker>

          </v-dialog>

        </v-col>

        <v-col>

          <v-dialog ref="dialog_end_date" v-model="date_end_modal" :return-value.sync="filters.filterByCustomDateEnd"
            width="290px">

            <template v-slot:activator="{ on }">

              <v-text-field dense outlined v-model="end_date_formatted" :disabled="disabled"
                :label="$t('filters-enddate-hint')" readonly clearable v-on="on"></v-text-field>

            </template>

            <v-date-picker @input="$refs.dialog_end_date.save(filters.filterByCustomDateEnd)"
              v-model="filters.filterByCustomDateEnd" scrollable :first-day-of-week="1"
              :locale="$store.state.user.locale">

            </v-date-picker>

          </v-dialog>

        </v-col>

      </v-row>

    </div>

  </div>

</template>

<style scoped>
.minfield {
  min-width: 100px;
}
</style>

<script>
import staticData from "@/apis/static";
import customersManager from "@/apis/customers";
import usersManager from "@/apis/users";
import productsManager from "@/apis/products";
import config from "@/config";
import products from "../apis/products";

export default {
  data() {
    return {
      loadingFilters: false,
      date_start_modal: false,
      date_end_modal: false,
      filtersChanged: false,

      orders_states: [

      ],

      warehouses: [],

      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,

      flt_product: null,
      flt_products: [],
      searchProductInput: null,
      isLoadingProducts: false,

      flt_user: null,
      flt_users: [],
      searchUserInput: null,
      isLoadingUser: false,

      customerCategories: [],
      productCategories: []
    };
  },

  mounted() {
    this.fetchFilters();
    this.orders_states = [{
      name: this.$t("gbl-all-male"),
      id: null
    }, ...staticData.orderStates]
  },

  computed: {
    start_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      }
    },

    end_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      }
    },

    getPeriodFilters: {
      get: function () {
        return staticData.statisticsPeriods;
      }
    }
  },

  methods: {
    onFiltersLoading: function () {
      this.$emit("onFiltersLoading");
    },

    onFiltersLoaded: function () {
      this.$emit("onFiltersLoaded");
    },

    fetchFilters: function () {
      this.filtersChanged = true;
      this.loadingFilters = true;
      this.onFiltersLoading();
      this.onFiltersLoaded();
      this.fetchCustomerCategories()
        .then(res => {
          this.customerCategories = [{ id: null, name: this.$t('gbl-all-male') }, ...res.items];
          this.fetchProductCategories().then((res) => {
            this.productCategories = [{ id: null, name: this.$t('gbl-all-male') }, ...res.items];
            products.fetchWarehouses().then((res) => {

              this.warehouses = [{ id: null, name: this.$t('gbl-all-male') }, ...res.warehouses];
              this.loadingFilters = false;
              this.onFiltersLoaded();
            }).catch(() => {
              this.loadingFilters = false;
              this.onFiltersLoaded();
            });


          }).catch(() => {
            this.productCategories = [];
            this.loadingFilters = false;
            this.onFiltersLoaded();
          })
        })
        .catch(() => {
          this.customerCategories = [];
          this.loadingFilters = false;
          this.onFiltersLoaded();
        });

    },

    fetchProductCategories() {
      return new Promise((resolve, reject) => {
        productsManager
          .fetchCategories()
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchCustomerCategories() {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchCategories()
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },



    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterProducts(filter) {
      return new Promise((resolve, reject) => {
        productsManager
          .fetchFilterProducts(filter, this.filters.filterByProductCategory)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterUser(filter) {
      return new Promise((resolve, reject) => {
        usersManager
          .fetchUsersNames(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    }
  },

  watch: {
    searchProductInput(val) {
      clearTimeout(this._searchProductInput);
      if (val) {
        this._searchProductInput = setTimeout(() => {
          this.isLoadingProducts = true;
          this.fetchFilterProducts(val, this.filters.filterByProductCategory)
            .then(res => {
              this.flt_products = res.products;
              this.isLoadingProducts = false;
            })
            .catch(() => {
              this.isLoadingProducts = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.filters.filterByProductId = null;
      }
    },

    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val && !this.flt_customers.some(item => item.display_name === val)) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;

          this.fetchFilterCustomers(val)
            .then(res => {
              this.flt_customers = res.customers.map(x => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.filters.filterByCustomerId = null;
      }
    },

    searchUserInput(val) {
      clearTimeout(this._searchUserInput);
      if (val && !this.flt_users.some(item => item.name === val)) {
        this._searchUserInput = setTimeout(() => {
          this.isLoadingUser = true;
          this.fetchFilterUser(val)
            .then(res => {
              this.flt_users = res.items.map(x => {
                x.name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingUser = false;
            })
            .catch(() => {
              this.isLoadingUser = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.filters.filterByUserId = null;
      }
    },



    flt_product: {
      handler() {
        this.filters.filterByProductId = this.flt_product
          ? this.flt_product.id
          : null;
      }
    },


    flt_user: {
      handler() {
        this.filters.filterByUserId = this.flt_user ? this.flt_user.id : null;
      }
    },

    flt_customer: {
      handler() {
        this.filters.filterByCustomerId = this.flt_customer
          ? this.flt_customer.id
          : null;
      }
    },

    "filters.filterByPeriod": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      }
    },

    "filters.filterByCustomDateStart": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      }
    },

    "filters.filterByCustomDateEnd": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      }
    }
  },
  props: ["disabled", "filters", "showLoader"]
};
</script>

<template>
<v-row dense :class="getClass()" @click="selectFile()">

<v-col cols="1" class="file_icon_col" >
<img :src="getIconPath" class="file_icon no-select">
</v-col>

<v-col class="text-left"  >
{{itemName(item)}}
</v-col>

<v-col class="text_cols" cols="3" >{{itemDate(item)}}</v-col>
<v-col class="text_cols" cols="2" >{{item.size}}</v-col>
</v-row>
</template>

<script>

const path = require('path');

export default {
    props: {
      item: Object
    },


    mounted() {
        this.domItem = this;
    },

    computed : {
        getIconPath() {
            let extension = path.extname(this.item.name);
            extension = extension.replace(".","");
            if (this.supportedExtensions.indexOf(extension) >= 0) {
                return require( "../assets/files/" + extension + ".png");
            } else {
                return require( "../assets/files/" + this.defaultExtension + ".png");
            }
        }
    },

     data() {
      return {

       supportedExtensions : [
          'afx','ai','avi','css','dll','doc','dwg',
          'eps','flv','gif','html','iso','jpg','js',
          'mp3','mp4','pdf','png','ppt','psd','raw',
          'tiff','xls','xlsx','zip'
        ],

        defaultExtension : 'default',
      }
    },

    methods : {
        getClass() {
            if (this.item.isSelected) return "file-item-selected";
            else return "file-item";
        },

        itemDate(item) {
            let date = new Date(Date.parse(item.modified));
            var formatter = new Intl.DateTimeFormat('it-IT', { day : '2-digit' , month : '2-digit' ,  year : 'numeric', hour: 'numeric', minute: 'numeric' , second : 'numeric'});
            return formatter.format(date);
        },

        itemName(item) {
            return path.basename(item.fullName);
        },

        selectFile() {
            this.$emit('itemSelected', this);
        },

        
        
    }
}

</script>

<style scoped>

.file-column-container {

}

.file-item {
    padding-left:5px;
    padding-right:5px;
    background-color:white;
    cursor: pointer;
    display: flex;
    align-items: center;
   
}

.file-item-selected {
    padding-left:5px;
    padding-right:5px;
    background-color:#fda55a;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
}

.file-item:hover {
    background-color:#eeeeee;
}

.file_icon_col {
    max-width: 48px;
}

.file_icon {
    width: 48px;
    height: 48px;
    vertical-align:middle;   
}

.file_icon_text {
    vertical-align:middle;
}

.text_cols {
    vertical-align:middle;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

</style>